/*----------------------header------------------------*/

.header_show {
    transition: .4S;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(#131314,0);
    z-index: 10;
    li {
        list-style: none;
    }
    &.active{
        background: rgba(#131314,1);
    }
}

.header_box {
    position: relative;
    width: 100%;
    height: 98px;
    padding: 0 110px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: pxToEm(14);
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba(#000 , .16); 
    @media (max-width:1199px) {
        padding: 0 40px;
    }
    @media (max-width:991px) {
        padding: 0 25px;
    }
}



.logo {
    width: 166px;
    height: 52px;
    background: url(../images/logo.png) top left no-repeat;
    background-size: contain;
    text-indent: -10000px;
    @media (max-width:991px) {
        width: 120px;
        height: 42px;
    }
    a {
        @include aLink();
    }
    h1{
        display: none;
    }
}


/*----------------------nav------------------------*/

.nav_box {
    position: relative;
    display: flex;
    align-items: center;
    .link-bx{
        margin-left: 35px;
        display: flex;
        a{
            display: block;
            width: 40px;
            position: relative;
            transition: .4s ease;
            &:not(:last-child){
                margin-right: 15px;
            }
            img{
                transition: .4s ease;
            }
            .unhov{

            }
            .hov{
                position: absolute;
                width: 40px;
                height: 40px;
                top: 0;
                left: 0;
                opacity: 0;
            }
            @media (min-width:768px) {
                &:hover{
                    .unhov{
                        opacity: 0;
                    }
                    .hov{
                        opacity: 1;
                    }
                }
            }
        }
    }
  
    ul {
        display: flex;
        li.active{
            a{
                color: $hover;
            }
        }
        li{
            position: relative;
            text-align: center;
            width: auto;
            &::before{
                transition: .4s;
                position: absolute;
                content:'';
                width: 1px;
                height: 69%;
                top: 5px;
                right: -20px;
                background: #6A6A6A;
            }
            &:nth-child(4){
                &::before{
                    display: none;
                }
            }
            a{
                @media (min-width:768px) {
                    &:hover{
                        color: $hover;
                    }
                }
            }
        }
        li:not(:last-child){
            margin-right: 40px;
        }
    }
    a{
        width: 100%;
        display: block;
        color: #6A6A6A;
        font-size: pxToEm(18);
        line-height: 1.5;
        letter-spacing: 1.5px;
        font-weight: 300;
        position: relative;
       
      
        &:hover{
           
            .en{
                opacity: 0;
            }
            .tw{
                opacity: 1;
            }
        }
    }
}


/*----------------------nav icon------------------------*/

.menu-wrapper {
    display: none;
}

$bar-width: 41px;
$bar-height: 2px;
$bar-spacing: 14px;
$bar-color:$hover;
.menu-wrapper {
    display: none;
    position: absolute;
    top: 0;
    right: 25px;
    bottom: 0;
    margin: auto;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
    z-index: 10;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
    width: $bar-width;
    height: $bar-height;
    @include border();
}

.hamburger-menu {
    position: relative;
    transform: translateY($bar-spacing);
    background: $bar-color;
    transition: all 0ms 300ms;
    @include border(99px);
    &.animate {
        background: rgba(255, 255, 255, 0);
    }
}

.hamburger-menu:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: $bar-color;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: $bar-color;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu.animate:after {
    top: 0;
    transform: rotate(42deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

.hamburger-menu.animate:before {
    bottom: 0;
    transform: rotate(-42deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

//------------------------- Menu icon -------------------------//
.menu-icon {
    display: none;
    top: 45%;
    right: -0.7em;
    z-index: 100;
    width: 45px;
    height: 39px;
    margin-top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: absolute;
    @media (max-width: $breakpoint-tablet) {
        display: block;
    }
}

//------------------------- Mobie Menu icon -------------------------//
.menu-icon-block {
    display: none;
    position: relative;
    height: 100%;
    margin: auto;
    i {
        position: absolute;
        display: block;
        margin: auto;
        width: 72%;
        height: 2px;
        background-color: $white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;
        &.i1 {
            top: 0%;
        }
        &.i2 {
            top: 25%;
        }
        &.i3 {
            top: 51%;
        }
    }
}




@media (max-width:767px){
    $header_heright: 60px;

    .logo{
        width: 120px;
        height: 37px;
    }
    .header_show{
        // background: $black;
    }
    .header_box {
        width: 100%;
        height:  $header_heright;
        font-size: pxToEm(17);
        margin: 0 auto;
        padding: 0 25px;
    }
    .nav_box nav{
        width: 100%;
        margin: auto;
    }
    .nav_box {
        display: none;
        position: fixed;
        top:  $header_heright;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        // background:#6E6E6E ;
        background:rgba(#6E6E6E , .95);
        a{
            font-size: pxToEm(20);
        }
        ul {
            display: block;
            margin-top: 55px;
            li {
                width: 100%;
                position: relative;
                &::before{
                    width: 20px;
                    height: 1px;
                    background: #fff;
                    bottom: 0;
                    top: auto;
                    right: auto;
                    left: 50%;
                    transform: translateX(-50%);
                }
                a {
                    padding: 30px 15px;
                    text-align: center;
                    text-decoration: none;
                    color: #fff;
                }
            }
        }
        ul>li:not(:last-child) {
            margin-right: 0;
        }
    }
    .nav_box .link-bx{
        justify-content: center;
        margin-left: 0;
        margin-top: 15%;
        a{
            margin: 0 20px;
            &:not(:last-child){
                margin-right: 0px;
            }
            .unhov{
                opacity: 0;
            }
            .hov{
                opacity: 1;
            }
        }
    }
    .menu-wrapper {
        display: block;
    }

}


