footer{
    z-index : -10;
    padding: 35px 145px 30px 110px;
    background-color: #BBC555;
    @media (max-width:1199px) {
        padding: 35px 40px 30px 40px;
    }
    @media (max-width:991px) {
        padding: 25px 25px 15px 25px;
    }
    .f-obx{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width:991px) {
            display: block;
        }
        .tbx{
            order: 2;
            max-width: 665px;
            @media (max-width:991px) {
                margin-bottom: 45px;
                max-width: 100%;
            }
            ul{
                flex-wrap: wrap;
                display: flex;
                @media (max-width:991px) {
                    display: block;
                }
                li{
                    display: flex;
                    font-family: $font_content;
                    &:nth-child(1){
                        margin-right: 20px;
                    }
                    &:nth-child(2){
                        margin-right: 20px;
                    }
                    span{
                        @include fontset(16,2,1,400,#71782E);
                        padding-right: 10px;
                        margin-right: 10px;
                        position: relative;
                        @media (max-width:991px) {
                            font-size: pxToEm(14);
                            margin-right: 8px;
                            padding-right: 8px;
                            letter-spacing: 0;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            right: 0;
                            top: 10px;
                            width: 1px;
                            height: 50%;
                            background: #71782E;
                        }
                    }
                    a,div{
                        @include fontset(16,2,1,400,#fff);
                        @media (max-width:991px) {
                            font-size: pxToEm(14);
                            letter-spacing: 0;
                        }
                    }
                }
            }
            .adr{
                @include fontset(16,1.3,1,400,#fff);
                font-family: $font_content;
                @media (max-width:991px) {
                    font-size: pxToEm(14);
                    letter-spacing: 0;
                }
            }
        }
        .bbx{
            order: 1;
            .f-logo{
                @media (max-width:991px) {
                    width: 126px;
                }
            }
            .copyright{
                @include fontset(14,1.3,1,400,#71782E);
                margin-top: 12px;
                font-family: $font_content;
                @media (max-width:991px) {
                    font-size: pxToEm(12);
                    letter-spacing: 0;
                    margin-top: 6px;
                }
            }
        }   
    }
}



