.indexPage{
    main{
        @media (max-width:767px) {
            margin-top: 0;
        }
    }
    .banner-bx{
        width: 100%;
        height: calc(100vh - 98px);
        @media (max-width:991px) and (min-width:768px) {
            height: auto;
        }
        @media (max-width:767px) and (min-width:451px) {
            height: auto;
        }
        @media (max-width:450px) {
            height: 100vh;
        }
        .bgcover{
            height: calc(100vh - 98px);
            background-size: cover !important;
            background-position: center center !important;
            @media (max-width:991px) and (min-width:768px) {
                height: 0;
                padding-bottom: 51.1%;
            }
            @media (max-width:767px) and (min-width:451px) {
                height: 0;
                padding-bottom: 177.8%;
            }
            @media (max-width:450px) {
                height: 100vh;
                padding-bottom: 0;
            }
        }
        a{
            display: block;
        }
        .slick-dots{
            text-align: center;
            right: 0;
            @media (max-width:767px) {
                text-align: center;
                right: auto;
            }
            li.slick-active{
                button:before{
                    background-color: $hover;
                }
            }
            li{
                width: 47px;
                height: 20px !important;
                button{
                    width: 47px;
                    height: 20px !important;
                    padding: 0;
                    &::before{
                        width: 47px;
                        height: 3px;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        border-radius: 0;
                        background-color: #ffffff;
                        transition: .4s;
                    }
                }
            }
        }
    }
    .item1{
        margin-top: 120px;
        padding-bottom: 140px;
        @media (max-width:767px) {
            margin-top: 60px;
            padding-bottom: 70px;
        }
        .it1list{
            margin-top: 60px;
            position: relative;
            @media (max-width:767px) {
                margin-top: 30px;
            }
            li{
                max-width: 610px;
                width: 100%;
                height: 265px;
                box-shadow: 5px 5px 10px 0px rgba(#000 , .4); 
                position: relative;
                @media (max-width:767px) {
                    height: 147px;
                    box-shadow: 2.5px 2.5px 5px 0px rgba(#000 , .4); 
                }
                .des{
                    position: absolute;
                    @include fontset(30,1.5,1,400,#fff);
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width: 313px;
                    height: 45px;
                    overflow: hidden;
                    @media (max-width:767px) {
                        font-size: pxToEm(18);
                        width: 190px;
                    }
                }
                .morebtn{
                    position: absolute;
                    bottom: 24px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 144px;
                    height: 42px;
                    border: 2px solid #fff;
                    transition: .4s ease;
                    @include fontset(18,2.2,.25,400,#fff);
                    text-align: center;
                    border-radius: 5px;
                    font-family: $font_content;
                    @media (max-width:767px) {
                        width: 90px;
                        height: 26px;
                        border-radius: 2px;
                        font-size: pxToEm(14);
                        line-height: 1.6;
                        bottom: 18px;
                    }
                }

                &:nth-child(odd){
                    // background: #A1AA49;
                    margin-left: auto;
                    margin-right: 0;
                    background-image: linear-gradient(to bottom,#A1AA49,#BBC555 68%);
                    
                    .des{
                        left: 40%;
                    }
                    @media (min-width:768px) {
                        .morebtn{
                            &:hover{
                                background-color: #fff;
                                color: #A1AA49;
                            }
                        }
                    }
                }

                &:nth-child(even){
                    // background: #6E6E6E;
                    background-image: linear-gradient(to bottom,#6E6E6E,#949494 74%);
                    &::before{
                        position: absolute;
                        content: '';
                        top: 30px;
                        right: 0;
                        width: calc(100% - 213px);
                        height: 2px;
                        background-color: #fff;
                        @media (max-width:767px) {
                            width: calc(100% - 103px);
                            top: 17px;
                            height: 1px;
                        }
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        left: 30px;
                        bottom: 0;
                        width: 2px;
                        height: calc(100% - 175px);
                        background-color: #fff;
                        @media (max-width:767px) {
                            left: 17px;
                            height: calc(100% - 90px);
                            width: 1px;
                        }
                    }
                    .img-bx{
                        position: absolute;
                        top: 20px;
                        left: 30px;
                        @media (max-width:767px) {
                            width: 63px;
                            top: 10px;
                            left: 15px;
                        }
                    }
                    .des{
                        left: 60%;
                    }
                    @media (min-width:768px) {
                        .morebtn{
                            &:hover{
                                background-color: #fff;
                                color: #6E6E6E;
                            }
                        }
                    }
                }

                &:nth-child(1){
                    z-index: 10;
                    &::before{
                        position: absolute;
                        content: '';
                        top: 30px;
                        right: 0;
                        width: calc(100% - 213px);
                        height: 2px;
                        background-color: #fff;
                        @media (max-width:767px) {
                            width: calc(100% - 103px);
                            top: 17px;
                            height: 1px;
                            left: 0;
                            right: auto;
                        }
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        left: 30px;
                        bottom: 0;
                        width: 2px;
                        height: calc(100% - 175px);
                        background-color: #fff;
                        @media (max-width:767px) {
                            right: 17px;
                            left: auto;
                            height: calc(100% - 90px);
                            width: 1px;
                        }
                    }
                    .img-bx{
                        position: absolute;
                        top: 20px;
                        left: 30px;
                        @media (max-width:767px) {
                            width: 63px;
                            top: 17px;
                            left: auto;
                            right: 15px;
                        }
                        
                    }
                    .des{
                        left: 60%;
                        @media (max-width:767px) {
                            left: 40%;
                        }
                    }
                }

                &:nth-child(2){
                    z-index: 9;
                    .img-bx{
                        position: absolute;
                        top: 20px;
                        right: 30px;
                        @media (max-width:767px) {
                            top: 10px;
                            left: 15px;
                        }
                    }
                   
                }
                &:nth-child(3){
                    z-index: 8;
                    &::before{
                        position: absolute;
                        content: '';
                        top: 30px;
                        left: 0;
                        width: calc(100% - 213px);
                        height: 2px;
                        background-color: #fff;
                        @media (max-width:767px) {
                            width: calc(100% - 103px);
                            top: 17px;
                            height: 1px;
                            left: 0;
                            right: auto;
                        }
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        right: 30px;
                        bottom: 0;
                        width: 2px;
                        height: calc(100% - 175px);
                        background-color: #fff;
                        @media (max-width:767px) {
                            right: 17px;
                            left: auto;
                            height: calc(100% - 90px);
                            width: 1px;
                        }
                    }
                    .img-bx{
                        position: absolute;
                        top: 20px;
                        left: auto;
                        right: 30px;
                        @media (max-width:767px) {
                            width: 63px;
                            top: 17px;
                            left: auto;
                            right: 15px;
                        }
                    }
                }
                &:nth-child(4){
                    z-index: 7;
                }

                &:not(:first-child){
                    margin-top: -65px;
                    @media (max-width:991px) {
                        margin-top: 70px;
                    }
                    @media (max-width:767px) {
                        margin-top: 35px;
                    }
                }
            }
        }
    }
    .item2{
        padding-top: 75px;
        padding-bottom: 120px;
        background-color: #fff;
        @media (max-width:767px) {
            padding-top: 37px;
            padding-bottom: 60px;
        }
        .editor_Content{
            margin-top: 70px;
            @media (max-width:767px) {
                margin-top: 35px;
            }
        }
    }
    .item3{
        background-color: #fff;
        padding-bottom: 140px;
        @media (max-width:767px) {
            padding-bottom: 105px;
        }
        .sh-conbx{
            margin-top: 75px;
            @media (max-width:767px) {
                margin-top: 25px;
            }
        }
    }
}

.aboutPage{
    // bg
    .item1{
        &::before{
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            background : url(../images/cover.png) no-repeat center center / cover;
            width: 1920px;
            height: 500px;
            z-index: -1;
            top: -320px;
            @media (max-width:767px) {
                top: -80px;
                height: 200px;
                width: 100%;
            }
        }
    }
    .item3{
        &::before{
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            background : url(../images/cover.png) no-repeat center center / cover;
            width: 1920px;
            height: 500px;
            z-index: -1;
            bottom: -300px;
            @media (max-width:767px) {
                bottom: -70px;
                height: 200px;
                width: 100%;
            }
        }
    }

    .item1{
        .it1-bx{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width:767px) {
                display: block;
            }
            .l{
                width: 41.5%;
                text-align: center;
                position: relative;
                @media (max-width:767px) {
                    width: 100%;
                    margin-bottom: 55px;
                }
               
                .en-ti{
                    @include fontset(32,1.4,.25,400,$hover);
                    @media (max-width:767px) {
                        font-size: pxToEm(24);
                        margin-bottom: 4px;
                    }
                }
                .ti{
                    @include fontset(40,1.3,.25,700,#000);
                    font-family: $ser;
                    @media (max-width:767px) {
                        font-size: pxToEm(30);
                    }
                }
            }
            .r{
                width: 60%;
                @include fontset(18,1.9,.35,400,#686868);
                padding-left: 10%;
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    left:  0;
                    top: 6px;
                    height: 90%;
                    width: 1px;
                    background-color: #686868;
                    @media (max-width:767px) {
                        right: auto;
                        left: 50%;
                        transform: translateX(-50%);
                        top: -30px;
                        bottom: auto;
                        width: 63px;
                        height: 1px;
                    }
                }
                @media (max-width:767px) {
                    width: 100%;
                    padding-left: 0;
                    line-height: 1.7;
                }
            }
        }
    }
    .item2{
        display: flex;
        align-items: center;
        margin-top: 75px;
        padding-top: 120px;
        position: relative;
        margin-bottom: 175px;
        @media (max-width:991px) {
            display: block;
            padding-top: 45px;
            margin-bottom: 45px;
        }
        .it2-bg{
            position: absolute;
            top: 0;
            right: 90px;
            @media (max-width:991px) {
                right: 15px;
                width: 91%;
            }
        }
        .l{
            width: 50%;
            position: relative;
            z-index: 5;
            @media (max-width:991px) {
                width: 100%;
            }
            .bgcover{
                width: 100%;
                // height: 705px;
                padding-bottom: 72.8%;
                background-size: cover !important;
            }
        }
        .r{
            width: 50%;
            // height: 705px;
            height: 0;
            padding-bottom: 36.4%;
            position: relative;
            background-image: linear-gradient(to right,#BBC555,#B2BC51,#E7F556);
            box-shadow: 0 3px 11px 4px rgba(0,0,0,.2);
            @media (max-width:991px) {
                width: 100%;
                padding-bottom: 30px;
                height: auto;
                background-image: linear-gradient(to top,#BBC555,#B2BC51,#E7F556);
                box-shadow: 0 3px 11px 4px rgba(0,0,0,.2);
            }
            .card{
                position: absolute;
                width: 75%;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                @media (max-width:1199px) {
                    width: 85%;
                }
                @media (max-width:991px) {
                    position: relative;
                    top: auto;
                    left: auto;
                    transform: none;
                    width: 90%;
                    margin: 0 auto;
                }
                .t-bx{
                    padding-bottom: 3vw;
                    margin-bottom: 3vw;
                    border-bottom: 1px solid #000000;
                    @media (max-width:1199px) {
                        padding-bottom: 1vw;
                        margin-bottom: 1vw;
                    }
                    @media (max-width:991px) {
                        padding-top: 27px;
                        padding-bottom: 40px;
                        margin-bottom: 25px;
                    }
                    ul{
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        @media (max-width:991px) {
                            display: block;
                        }
                        li{
                            width: auto;
                            text-align: center;
                            @media (max-width:991px) {
                                width: 100%;
                                &:not(:last-child){
                                    margin-bottom: 70px;
                                }
                            }
                            .des{
                                @include fontset(20,1.5,1.25,700);
                                margin-top: 39px;
                                @media (max-width:1199px) {
                                    margin-top: 8px;
                                }
                                @media (max-width:991px) {
                                    font-size: pxToEm(18);
                                    margin-top: 14px;
                                }
                            }
                            .img-bx{
                                @media (max-width:1199px) {
                                    width: 50%;
                                    margin: 0 auto;
                                }
                                @media (max-width:991px) {
                                    width: 25%;
                                }
                            }
                        }
                    }
                }
                .b-bx{
                    
                    .des{
                        @include fontset(18,1.9,1.25,400);
                        @media (max-width:991px) {
                            line-height: 1.7;
                        }
                    }
                }
            }
        }
    }
    .item3{
        margin-bottom: 180px;
        @media (max-width:767px) {
            margin-bottom: 105px;
        }
        .it3-list{
            margin-top: 70px;
            width: calc(100% + 20px);
            transform: translateX(-10px);
            user-select: auto;
            @media (max-width:1199px){
                width: calc(85% + 20px);
                margin: 0 auto;
                transform: none;
            }
            @media (max-width:767px) {
                margin-top: 45px;
                width: calc(78% + 20px);
            }
            li{
                position: relative;
                padding-bottom: 120px;
                background-color: #fff;
                box-shadow: 0 0px 5px 0px rgba(0,0,0,.3);
                margin: 5px 10px;
                transition: .4s ease;
                @media (min-width:768px) {
                    &:hover{
                        background-color: #F2F2F2;
                    }
                }
                @media (max-width:767px) {
                    padding-bottom: 90px;
                }
            }
            .img-bx{
                width: 100%;
                @media (max-width:767px) {
                    height: 235px;
                    overflow: hidden;
                }
                img{
                    width: 100%;
                }
            }
            .des-bx{
                padding: 0 25px;
                @media (max-width:767px) {
                    padding: 0 20px;
                }
            }
            .ti{
                @include fontset(35,1.5,1,700);
                margin-bottom: 10px;
                margin-top: 25px;
                @media (max-width:767px) {
                    font-size: pxToEm(24);
                    margin-top: 22px;
                    margin-bottom: 3px;
                }
            }
            .des{
                @include fontset(18,1.8,1,400,#686868);
                height: 220px;
                overflow: hidden;
                @media (max-width:767px) {
                    font-size: pxToEm(14);
                    height: 172px;
                }
            }
            .wo-pos{
                position: absolute;
                bottom: 25px;
                left: 25px;
                @include fontset(20,1.5,1,700);
                width: calc(100% - 50px);
                @media (max-width:767px) {
                    font-size: pxToEm(14);
                    width: calc(100% - 40px);
                    left: 20px;
                }
                &::before{
                    position: absolute;
                    content: '';
                    top: -10px;
                    width: 100%;
                    height: 1px;
                    background-color: #686868;
                }
            }
            .slick-dots{
                display: none !important;
            }
            .slick-prev,.slick-next{
                width: 65px;
                height: 35px;
                // @include fontset(18,1.5,1,400,#686868);
                @media (max-width:767px) {
                    width: 25px;
                }
            }
            .slick-prev{
                background:url(../images/PREV.png) no-repeat right center / 40px;
                left: -85px;
                padding-left: 25px;
                @media (max-width:1199px){
                    left: -70px;
                }
                @media (max-width:767px) {
                    background: none;
                    left: -25px;
                }
                &::before{
                    position: absolute;
                    content: '';
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    transition: .4s ease;
                    background-color: #686868;
                    @media (max-width:767px) {
                        height: 1px;
                    }
                }
                &::after{
                    position: absolute;
                    content: '';
                    left: 4px;
                    bottom: -1px;
                    width: 2px;
                    height: 13px;
                    background-color: #686868;
                    transition: .4s ease;
                    transform: rotate(45deg);
                    @media (max-width:767px) {
                        width: 1px;
                        left: 2px;
                        height: 7px;
                    }
                }
                @media (min-width:768px) {
                    &:hover{
                        &::before,&::after{
                            background-color: $hover;
                        }
                        &::before{
                            width: calc(100% + 25px);
                        }
                        &::after{
                            left: -21px;
                        }
                    }
                }
            }
            .slick-next{
                background:url(../images/NEXT.png) no-repeat left center / 40px;
                right: -85px;
                @media (max-width:1199px){
                    right: -70px;
                }
                @media (max-width:767px) {
                    background: none;
                    right: -25px;
                }
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    transition: .4s ease;
                    background-color: #686868;
                    @media (max-width:767px) {
                        height: 1px;
                    }
                }
                &::after{
                    position: absolute;
                    content: '';
                    right: 4px;
                    bottom: -1px;
                    width: 2px;
                    height: 13px;
                    background-color: #686868;
                    transition: .4s ease;
                    transform: rotate(-45deg);
                    @media (max-width:767px) {
                        width: 1px;
                        right: 2px;
                        height: 7px;
                    }
                }
                @media (min-width:768px) {
                    &:hover{
                        &::before,&::after{
                            background-color: $hover;
                        }
                        &::before{
                            width: calc(100% + 25px);
                        }
                        &::after{
                            right: -21px;
                        }
                    }
                }
            }
        }
    }
}

.servicePage{
     // bg
     .item1{
        &::before{
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            background : url(../images/cover.png) no-repeat center center / cover;
            width: 1920px;
            height: 500px;
            z-index: -1;
            top: -320px;
            @media (max-width:767px) {
                top: -80px;
                height: 200px;
                width: 100%;
            }
        }
    }
    .item3{
        &::before{
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            background : url(../images/cover.png) no-repeat center center / cover;
            width: 1920px;
            height: 500px;
            z-index: -1;
            bottom: -140px;
            @media (max-width:767px) {
                bottom: -15px;
                height: 200px;
                width: 100%;
            }
        }
    }

    .item1{
        background: #fff;
    }
    .item2{
        padding-top: 40px;
        background: #fff;
        @media (max-width:991px) {
            padding-top: 0px;
            margin-top: -20px;
        }
    }
    .item3{
        padding-top: 100px;
        margin-bottom: 130px;
        background: #fff;
        @media (max-width:991px) {
            padding-top: 25px;
            margin-bottom: 85px;
        }
        .it3-list{
            li{
                position: relative;
                &:not(:last-child) a{
                    padding-bottom: 50px;
                    margin-bottom: 48px;
                    border-bottom: 1px solid #C8C8C8;
                    @media (max-width:991px) {
                        padding-bottom: 60px;
                    }
                }
                @media (max-width:991px) {
                    padding-bottom: 0px;
                    
                }
                a{
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    align-items: center;
                    @media (max-width:991px) {
                        display: block;
                        padding-bottom: 60px;
                    }
                }
                .l{
                    width: 45%;
                    @media (max-width:991px) {
                        width: 100%;
                    }
                    .bgcover{
                        padding-bottom: 53.6%;
                        background-size: cover !important;
                    }
                }
                .r{
                    width: 49.4%;
                    @media (max-width:991px) {
                        width: 100%;
                    }
                    .group{
                        @include fontset(24,1.4,1,400,$hover);
                        padding-bottom: 24px;
                        margin-bottom: 22px;
                        position: relative;
                        font-family: $font_content;
                        @media (max-width:991px) {
                            margin-top: 15px;
                            padding-bottom: 13px;
                            margin-bottom: 12px;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            bottom: 0;
                            left: 0;
                            width: 25px;
                            height: 6px;
                            background-color: $hover;
                            @media (max-width:991px) {
                                height: 3px;
                                width: 14px;
                            }
                        }
                    }
                    .des{
                        @include fontset(18,2,1,400,#686868);
                        font-family: $font_content;
                    }
                }
                .more-btn{
                    position: absolute;
                    top: 0;
                    right: 0;
                    @include fontset(18,2,1,400,#686868);
                    font-family: $font_content;
                    @media (max-width:991px) {
                        top: auto;
                        bottom: 25px;
                        font-size: pxToEm(14);
                        padding-right: 6px;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        bottom: 0;
                        width: calc(100% + 15px);
                        height: 2px;
                        transition: .4s ease;
                        background-color: #686868;
                        @media (max-width:991px) {
                            width: 100%;
                        }
                        @media (max-width:767px) {
                            height: 1px;
                        }
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        right: -11px;
                        bottom: -1px;
                        width: 2px;
                        height: 13px;
                        background-color: #686868;
                        transition: .4s ease;
                        transform: rotate(-45deg);
                        @media (max-width:767px) {
                            width: 1px;
                            right: 2px;
                            height: 7px;
                        }
                    }
                }
                @media (min-width:768px) {
                    &:hover{
                        .more-btn::before,.more-btn::after{
                            background-color: $hover;
                        }
                        .more-btn::before{
                            width: calc(100% + 35px);
                        }
                        .more-btn::after{
                            right: -30px;
                        }
                    }
                }
            }
        }
        .page{
            margin-top: 140px;
            @media (max-width:991px) {
                margin-top: 45px;
            }
        }
    }

}

.servicedetailPage{
    //bg
    .item1{
        background-color: #fff;
        &::before{
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            background : url(../images/cover.png) no-repeat center center / cover;
            width: 1920px;
            height: 500px;
            z-index: -1;
            top: -320px;
            @media (max-width:767px) {
                top: -80px;
                height: 200px;
                width: 100%;
            }
        }
    }
    .item2{
        background-color: #fff;
        &::before{
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            background : url(../images/cover.png) no-repeat center center / cover;
            width: 1920px;
            height: 500px;
            z-index: -1;
            bottom: -140px;
            @media (max-width:767px) {
                bottom: -15px;
                height: 200px;
                width: 100%;
            }
        }
    }

    .item2{
        padding-top: 40px;
        margin-bottom: 140px;
        @media (max-width:991px) {
            padding-top: 0;
            margin-top: -20px;
            margin-bottom: 90px;
        }
        .ti{
            @include fontset(24,1.5,1,500);
            font-family: $font_content;
            margin-bottom: 58px;
            @media (max-width:991px) {
                margin-bottom: 29px;
            }
        }
        .back-btn{
            position: relative;
            display: block;
            width: 78px;
            height: 30px;
            margin: 0 auto;
            padding-left: 24px;
            margin-top: 50px;
            font-family: $font_content;
            @include fontset(18,1.5,1,400,#686868);
            @media (max-width:767px) {
                margin-top: 25px;
                font-size: pxToEm(14);
                width: 64px;
                height: 24px;
            }
            &::before{
                position: absolute;
                content: '';
                right: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                transition: .4s ease;
                background-color: #686868;
                @media (max-width:767px) {
                    height: 1px;
                }
            }
            &::after{
                position: absolute;
                content: '';
                left: 4px;
                bottom: -1px;
                width: 2px;
                height: 13px;
                background-color: #686868;
                transition: .4s ease;
                transform: rotate(45deg);
                @media (max-width:767px) {
                    width: 1px;
                    left: 2px;
                    height: 7px;
                }
            }
            @media (min-width:768px) {
                &:hover{
                    &::before,&::after{
                        background-color: $hover;
                    }
                    &::before{
                        width: calc(100% + 25px);
                    }
                    &::after{
                        left: -21px;
                    }
                }
            }
        }
    }

}

.casePage{
     //bg
     .item1{
        &::before{
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            background : url(../images/cover.png) no-repeat center center / cover;
            width: 1920px;
            height: 500px;
            z-index: -1;
            top: -320px;
            @media (max-width:767px) {
                top: -80px;
                height: 200px;
                width: 100%;
            }
        }
        &::after{
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            background : url(../images/cover.png) no-repeat center center / cover;
            width: 1920px;
            height: 500px;
            z-index: -1;
            bottom: -140px;
            @media (max-width:767px) {
                bottom: -15px;
                height: 200px;
                width: 100%;
            }
        }
    }
   

    .item1{
        margin-bottom: 125px;
        background-color: #fff;
        @media (max-width:767px) {
            margin-top: 66px;
        }
        .sh-conbx{
            margin-top: 125px;
            @media (max-width:767px) {
                margin-top: 66px;
            }
        }
    }
}


.conPage{
 //bg
    .item1{
        &::before{
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            background : url(../images/cover.png) no-repeat center center / cover;
            width: 1920px;
            height: 500px;
            z-index: -1;
            top: -320px;
            @media (max-width:767px) {
                top: -80px;
                height: 200px;
                width: 100%;
            }
        }
    }
    .item2{
        &::before{
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            background : url(../images/cover.png) no-repeat center center / cover;
            width: 1920px;
            height: 500px;
            z-index: -1;
            bottom: -140px;
            @media (max-width:767px) {
                bottom: -15px;
                height: 200px;
                width: 100%;
            }
        }
    }

    .item1{
        .ti{
            @include fontset(30,1.3,.3,700);
            @media (max-width:767px) {
                font-size: pxToEm(24);
            }
        }
        .en-ti{
            @include fontset(24,1.4,.25,400,$hover);
            font-family: $font_content;
            @media (max-width:767px) {
                font-size: pxToEm(14);
            }
        }
        .flex-bx{
            display: flex;
            margin-top: 30px;
            @media (max-width:991px) {
                display: block;
            }
            @media (max-width:767px) {
                margin-top: 20px;
            }
            a,.fax,.adr{
                @include fontset(20,2,0,400);
                margin-bottom: 13px;
                display: block;
                font-family: $font_content;
                @media (max-width:767px) {
                    font-size: pxToEm(15);
                    margin-bottom: 8px;
                }
                span{
                    position: relative;
                    padding-left: 48px;
                    @media (max-width:767px) {
                        padding-left: 42px;
                    }
                    img{
                        position: absolute;
                        left: 0;
                        top: -7px;
                        @media (max-width:767px) {
                            min-width: 32px;
                            width: 32px;
                        }
                    }
                }
            }
            .fax{
                span img{
                    left: 4px;
                    @media (max-width:767px) {
                        min-width: 26px;
                        width: 26px;
                        top: -3px;
                        left: 2px;
                    }
                }
            }
            .l{
                width: 40%;
                @media (max-width:991px) {
                    width: 100%;
                }
            }
            .r{
                width: 60%;
                @media (max-width:991px) {
                    margin-top: -4px;
                    width: 100%;
                }
                @media (max-width:767px) {
                    margin-top: 12px;
                }
            
            }
        }
        .map-bx{
            margin-top: 30px;
            @media (max-width:767px) {
                margin-top: 15px;
            }
            iframe{
                height: 380px;
                border:1px solid #C8C8C8;
                @media (max-width:767px) {
                    height: 195px;
                }
            }
        }
    }
    .item2{
        margin-bottom: 120px;
        margin-top: 140px;
        padding-top: 95px;
        position: relative;
        @media (max-width:991PX) {
            margin-top: 0;
        }
        .container{
            position: relative;
        }
        .it2-bg{
            position: absolute;
            top: -82px;
            right: -66px;
            width: 55%;
            @media (max-width:991PX) {
                top: -10%;
                width: calc(100% - 50px);
                left: 50%;
                right: auto;
                transform: translateX(-50%);
            }
            @media (max-width:767px) {
                top: -47px;
            }
        }
        .it2-bx{
            padding: 65px 10%;
            background-color: #fff;
            box-shadow: 0 0 15px 0 rgba(0,0,0,.3);
            @media (max-width:991PX) {
                padding: 60px 25px;
                padding-bottom: 50px;
                box-shadow: 0 0 7.5px 0 rgba(0,0,0,.3);
            }
            .top-ti{
                @include fontset(20,1.75,1,400);
                padding-bottom: 5px;
                border-bottom: 1px solid #C8C8C8;
                position: relative;
                @media (max-width:991PX) {
                    font-size: pxToEm(15);
                }
                &::before{
                    position: absolute;
                    content: '';
                    bottom: -9px;
                    right: 0;
                    border-top: 9px solid #c8c8c8;
                    border-left: 7px dashed transparent;
                    border-right: 7px dashed transparent;
                    @media (max-width:991PX) {
                        border-top: 5px solid #c8c8c8;
                        border-left: 4px dashed transparent;
                        border-right: 4px dashed transparent;
                        bottom: -6px;
                    }
                }
            }
            .form-bx{
                margin-top: 50px;
                @media (max-width:991PX) {
                    margin-top: 28px;
                }
                .flex-bx{
                    display: flex;
                    justify-content: space-between;
                    @media (max-width:991PX) {
                        display: block;
                    }
                    .form-group{
                        width: 32%;
                        @media (max-width:991PX) {
                            width: 100%;
                            margin-bottom: 25px;
                        }
                    }
                }
                label{
                    @include fontset(20,2,1,400);
                    span{
                        color: #DC0000;
                    }
                    @media (max-width:991px) {
                        font-size: pxToEm(15);
                    }
                }
                input{
                    height: 55px;
                    background-color: #F2F2F2;
                    padding-left: 20px;
                    border-radius: 0;
                    border: none;
                    &:focus{
                        border: none;
                    }
                    @media (max-width:991px) {
                        height: 37px;
                    }
                }
                .help-block{
                    @include fontset(16,2,1,400,#DC0000);
                    height: 32px;
                    @media (max-width:991px) {
                        font-size: pxToEm(12);
                    }
                }
                .form-textbx{
                    .textarea-wrapper{
                        height: 250px;
                        overflow: hidden;
                        border:none;
                        padding:0px 0px 10px;
                        margin-top: 0px;
                        background-color: #F2F2F2;
                        @media (max-width:991px) {
                            margin-top: 0;
                        }
                        @media (max-width:767px) {
                            height: 125px;
                        }
                        textarea{
                            min-height: 237px;
                            height: 0;
                            overflow: hidden;
                            border-bottom:none;
                            padding-top:14px;
                            padding-left: 11px;
                            text-rendering: auto;
                            -webkit-writing-mode: horizontal-tb !important;
                            background-color: #F2F2F2;
                            resize: none;
                            border: none;
                            font-family: $font_content;
                            cursor:text;
                            @media (max-width:767px) {
                                min-height: 112px;
                            }
                            &:focus{
                                        border: none ;
                                    }
                        }
                    }
                    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
                        background-color: $hover;
                        width: 6px;
                        border-radius: 0;
                        @media (max-width:991px) {
                            width: 3px;
                        }
                    }
                    .mCSB_scrollTools .mCSB_draggerRail{
                        background-color: #b4b4b4;
                        width: 2px;
                        @media (max-width:991px) {
                            width: 1px;
                        }
                    }
                    .mCSB_scrollTools{
                        right: -5px;
                    }
                    .mCSB_inside>.mCSB_container{
                        margin-right: 13px;
                    }
                    .mCSB_scrollTools .mCSB_draggerContainer{
                        top: 15px;
                        left: -3px;
                        @media (max-width:991px) {
                            top: 6px;
                        }
                    }
                }
                .send-bx{
                    text-align: center;
                    a{
                        display: block;
                        width: 252px;
                        height: 72px;
                        background-color: $hover;
                        border: 1px solid $hover;
                        margin: 0 auto;
                        margin-top: 55px;
                        font-family: $font_content;
                        @include fontset(18,4,.25,400,#fff);
                        @media (max-width:991px) {
                            width: 185px;
                            height: 44px;
                            font-size: pxToEm(14);
                            line-height: 3.2;
                            margin-top: 35px;
                        }
                        @media (min-width:767px) {
                            &:hover{
                                background-color: #fff;
                                color: $hover;
                            }
                        }
                    }
                }
            }
        }
    }
}