@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
/* > reset */
/******************************************/
/*		slick
/******************************************/
/* Slider */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;700&family=Noto+Serif+TC:wght@300;400;700&display=swap");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		mCustomScrollbar
/******************************************/
/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
/******************************************/
/*		fullpage
/******************************************/
@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre,
form, fieldset, input, textarea, p, blockquote {
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal; }

strong {
  font-weight: bold; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
  color: #444; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0; }

/* Custom CSS
 * --------------------------------------- */
body {
  font-family: arial,helvetica;
  color: #333;
  color: rgba(0, 0, 0, 0.5); }

.wrap {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  position: relative; }

h1 {
  font-size: 6em; }

p {
  font-size: 2em; }

.intro p {
  width: 50%;
  margin: 0 auto;
  font-size: 1.5em; }

.section {
  text-align: center; }

#menu li {
  display: inline-block;
  margin: 10px;
  color: #000;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 10px;
  border-radius: 10px; }

#menu li.active {
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  color: #fff; }

#menu li a {
  text-decoration: none;
  color: #000; }

#menu li.active a:hover {
  color: #000; }

#menu li:hover {
  background: rgba(255, 255, 255, 0.8); }

#menu li a,
#menu li.active a {
  padding: 9px 18px;
  display: block; }

#menu li.active a {
  color: #fff; }

#menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  z-index: 70;
  width: 100%;
  padding: 0;
  margin: 0; }

.twitter-share-button {
  position: fixed;
  z-index: 99;
  right: 149px;
  top: 9px; }

#download {
  margin: 10px 0 0 0;
  padding: 15px 10px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -ms-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(top, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

#download a {
  text-decoration: none;
  color: #fff; }

#download:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #2F96B4;
  background-position: 0 -15px;
  -webkit-transition: background-position .1s linear;
  -moz-transition: background-position .1s linear;
  -ms-transition: background-position .1s linear;
  -o-transition: background-position .1s linear;
  transition: background-position .1s linear; }

#infoMenu {
  height: 20px;
  color: #f2f2f2;
  position: fixed;
  z-index: 70;
  bottom: 0;
  width: 100%;
  text-align: right;
  font-size: 0.9em;
  padding: 8px 0 8px 0; }

#infoMenu ul {
  padding: 0 40px; }

#infoMenu li a {
  display: block;
  margin: 0 22px 0 0;
  color: #333; }

#infoMenu li a:hover {
  text-decoration: underline; }

#infoMenu li {
  display: inline-block;
  position: relative; }

#examplesList {
  display: none;
  background: #282828;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 20px;
  float: left;
  position: absolute;
  bottom: 29px;
  right: 0;
  width: 638px;
  text-align: left; }

#examplesList ul {
  padding: 0; }

#examplesList ul li {
  display: block;
  margin: 5px 0; }

#examplesList ul li a {
  color: #BDBDBD;
  margin: 0; }

#examplesList ul li a:hover {
  color: #f2f2f2; }

#examplesList .column {
  float: left;
  margin: 0 20px 0 0; }

#examplesList h3 {
  color: #f2f2f2;
  font-size: 1.2em;
  margin: 0 0 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  padding: 0 0 5px 0; }

/* Demos Menu
 * --------------------------------------- */
#demosMenu {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

@font-face {
  font-family: "ebg-400";
  src: url(../assets/EBGaramond-Regular.ttf); }

/*把變數帶入mixin中，此mixin只就單純把media queries寫到mixin中，
並且使用上面設立的變數來調動media queries的width*/
main[role="main"], section, article {
  zoom: 1; }
  main[role="main"]:before, section:before, article:before, main[role="main"]:after, section:after, article:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role="main"]:after, section:after, article:after {
    clear: both; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
b::selection,
strong::selection,
span::selection,
li::selection,
div::selection,
a::selection,
img::selection,
tr::selection,
td::selection,
th::selection {
  color: #ffffff;
  background: #BBC555; }

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: 16px;
  color: #000000;
  word-break: break-word;
  letter-spacing: 0.1em;
  z-index: 0;
  background: #ffffff;
  -webkit-text-size-adjust: 100%;
  opacity: 0;
  transition: 1.2s;
  font-family: "ebg-400" "Noto Sans TC"; }

body.loading {
  opacity: 1;
  transition: opacity 1.2s; }

a {
  text-decoration: none;
  outline: 0; }

a,
a:hover {
  transition: 0.4s; }

img {
  vertical-align: middle;
  max-width: 100%; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */ }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

.jqimgFill img,
.jqimgFill-tc img {
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

input,
select,
textarea {
  width: 100%;
  height: 56px;
  outline: none;
  padding: 0;
  color: #000;
  border: 1px solid #ffffff;
  font-size: 1rem;
  border-radius: 5px; }
  @media screen and (max-width: 640px) {
    input,
    select,
    textarea {
      font-size: 0.9375rem; } }
  input:focus,
  select:focus,
  textarea:focus {
    transition: .4s;
    border: 1px solid #eee; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important; }

textarea {
  height: 218px;
  padding: 10px; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #C8C8C8;
  line-height: 18px; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #C8C8C8;
  line-height: 43px; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #C8C8C8;
  line-height: 43px; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #C8C8C8;
  line-height: 18px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type='date'],
input[type='time'] {
  -webkit-appearance: none; }

select {
  font-family: "Noto Sans TC";
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%; }

select::-ms-expand {
  display: none; }

.selectBox {
  background: #FFF; }

body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }
  @media screen and (max-width: 960px) {
    body :target:before {
      height: 100px;
      margin: -100px 0 0; } }

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }

.slick-slide {
  outline: 0; }

/*----------------------大區塊------------------------*/
#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden; }

main[role="main"] {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 98px;
  background: url(../images/line.png); }
  @media (max-width: 767px) {
    main[role="main"] {
      margin-top: 60px;
      background-size: 20px; } }

section {
  position: relative; }

article {
  width: 1200px;
  max-width: 85%;
  margin: 0 auto;
  position: relative;
  z-index: 1; }

/*----------------------每個選單Highlight------------------------*/
/*----------------------內頁單元標題------------------------*/
/*----------------------tag_list------------------------*/
.tag_list a {
  color: #ffffff;
  text-decoration: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #434343;
  padding: 3px 7px;
  font-size: 0.9375rem; }
  .tag_list a:not(:last-child) {
    margin-right: 15px; }
  .tag_list a:hover {
    background: #2f3a88; }

/*----------------------編輯器------------------------*/
.editor_Content {
  font-size: 1.125rem;
  line-height: 1.8;
  color: #686868; }
  .editor_Content ol {
    list-style-type: decimal;
    padding-left: 10px; }
  .editor_Content ul {
    list-style-type: initial;
    padding-left: 20px; }
  .editor_Content img {
    max-width: 100%;
    height: auto !important; }
  .editor_Content b, .editor_Content strong {
    font-weight: bold;
    font-size: inherit; }
  .editor_Content em {
    font-style: italic; }
  .editor_Content a {
    text-decoration: underline;
    color: #0782C1; }
  .editor_Content iframe {
    max-width: 100%; }
  .editor_Content h1, .editor_Content h2, .editor_Content h3, .editor_Content h4, .editor_Content h5, .editor_Content h6 {
    margin: inherit; }
  .editor_Content blockquote {
    font-style: italic;
    padding: 2px 20px 0 8px;
    margin-left: 40px;
    border-left: 5px solid #ccc; }
  .editor_Content td, .editor_Content th, .editor_Content tr {
    margin: initial; }
  .editor_Content table {
    border-collapse: inherit; }

.editor_Box {
  margin: auto;
  line-height: 1.66;
  font-size: 1.125rem;
  color: #686868; }
  .editor_Box ul,
  .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
  .editor_Box iframe {
    max-width: 100%; }
  .editor_Box h1,
  .editor_Box h2,
  .editor_Box h3,
  .editor_Box h4,
  .editor_Box h5,
  .editor_Box h6 {
    margin: inherit; }

.mo_use {
  display: none; }

/*----------------------back------------------------*/
.back_btn,
.more_btn {
  width: 100%;
  margin-top: 70px;
  font-size: 1.125rem; }
  .back_btn a,
  .more_btn a {
    margin: auto;
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    padding: 19.5px 49px;
    background-color: #4759d9;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
    .back_btn a:hover,
    .more_btn a:hover {
      background: #4759d9;
      opacity: .5; }

/*----------------------上下則------------------------*/
.seesaw {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin-top: 61px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .seesaw a {
    color: #de4242;
    text-decoration: none; }
  .seesaw .left_button:before,
  .seesaw .right_button:before,
  .seesaw .back_button {
    transition: 0.4s; }
  .seesaw .left_button,
  .seesaw .right_button {
    position: absolute; }
    .seesaw .left_button:before,
    .seesaw .right_button:before {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background: #de4242;
      margin-bottom: 2px; }
    .seesaw .left_button:hover:before,
    .seesaw .right_button:hover:before {
      width: 90px; }
  .seesaw .left_button {
    right: 0; }
  .seesaw .right_button {
    left: 0;
    text-align: right; }
  .seesaw .back_button {
    font-size: 1rem;
    width: 230px;
    height: auto;
    padding: 11px 0;
    border: 1px solid #de4242;
    text-align: center; }
    .seesaw .back_button:hover {
      background: #de4242; }
      .seesaw .back_button:hover a {
        color: #ffffff; }

/*----------------------GO TOP------------------------*/
.top_btn.show-topbtn {
  opacity: 1; }

.top_btn {
  cursor: pointer;
  transition: .4s;
  opacity: 0;
  position: fixed;
  bottom: 40px;
  right: 110px;
  z-index: 8;
  font-size: 1.125rem;
  z-index: 9;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #707070; }
  @media (min-width: 768px) {
    .top_btn:hover {
      background: #BBC555; } }
  @media (max-width: 767px) {
    .top_btn {
      bottom: 20px;
      font-size: 0.875rem; } }
  .top_btn span {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-family: "Noto Sans TC";
    text-align: center;
    padding-top: 14px; }
    .top_btn span::before {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 17px;
      height: 8px;
      background: url(../images/topbtn.png) no-repeat center center/contain; }
  .top_btn.fix {
    position: absolute; }

/*----------------------頁數------------------------*/
.page {
  font-size: 1.125rem;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 94px; }
  .page dt,
  .page dd {
    vertical-align: middle;
    display: inline-block;
    font-family: "Noto Sans TC"; }
    .page dt a,
    .page dd a {
      display: block;
      height: 32px;
      line-height: 30px;
      text-decoration: none;
      font-size: 0.875rem;
      line-height: 1.43;
      letter-spacing: 0px;
      font-weight: 400;
      color: #454545;
      position: relative; }
      .page dt a:hover,
      .page dd a:hover {
        color: #BBC555; }
        .page dt a:hover:before,
        .page dd a:hover:before {
          opacity: 1; }
  .page dd {
    width: 3.7%; }
    .page dd a::before {
      position: absolute;
      content: '';
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
      width: 17px;
      height: 1px;
      background: #BBC555;
      opacity: 0;
      transition: .4s; }
  .page dd.active a {
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    background: none;
    padding: 0;
    color: #BBC555; }
    .page dd.active a:before {
      opacity: 1; }
  .page dt.rtbn a,
  .page dt.ltbn a {
    width: auto;
    height: 32px;
    transition: 0.4s;
    font-family: "Noto Sans TC";
    position: relative;
    transform: translateY(-10px);
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0px;
    font-weight: 400;
    color: #686868; }
  .page .nopage {
    opacity: 0.2; }
    @media (min-width: 768px) {
      .page .nopage a {
        pointer-events: none !important; }
      .page .nopage a:hover::before {
        background-color: #686868 !important;
        width: calc(100% + 20px); }
      .page .nopage a:hover::after {
        background-color: #686868 !important; } }
  .page dt.ltbn a {
    margin-right: 17px; }
    .page dt.ltbn a::before {
      position: absolute;
      content: '';
      right: 0;
      bottom: 5px;
      width: calc(100% + 20px);
      height: 2px;
      transition: .4s ease;
      background-color: #686868; }
      @media (max-width: 991px) {
        .page dt.ltbn a::before {
          width: calc(100% + 15px); } }
      @media (max-width: 767px) {
        .page dt.ltbn a::before {
          height: 1px; } }
    .page dt.ltbn a::after {
      position: absolute;
      content: '';
      left: -16px;
      bottom: 4px;
      width: 2px;
      height: 13px;
      background-color: #686868;
      transition: .4s ease;
      transform: rotate(45deg); }
      @media (max-width: 991px) {
        .page dt.ltbn a::after {
          left: -13px;
          width: 1px;
          height: 7px; } }
    @media (min-width: 768px) {
      .page dt.ltbn a:hover::before, .page dt.ltbn a:hover::after {
        background-color: #BBC555; }
      .page dt.ltbn a:hover::before {
        width: calc(100% + 35px); }
      .page dt.ltbn a:hover::after {
        left: -31px; } }
  .page dt.rtbn a {
    margin-left: 17px;
    position: relative; }
    .page dt.rtbn a::before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 5px;
      width: calc(100% + 20px);
      height: 2px;
      transition: .4s ease;
      background-color: #686868; }
      @media (max-width: 991px) {
        .page dt.rtbn a::before {
          width: calc(100% + 15px); } }
      @media (max-width: 767px) {
        .page dt.rtbn a::before {
          height: 1px; } }
    .page dt.rtbn a::after {
      position: absolute;
      content: '';
      right: -16px;
      bottom: 4px;
      width: 2px;
      height: 13px;
      background-color: #686868;
      transition: .4s ease;
      transform: rotate(-45deg); }
      @media (max-width: 991px) {
        .page dt.rtbn a::after {
          right: -13px;
          width: 1px;
          height: 7px; } }
    @media (min-width: 768px) {
      .page dt.rtbn a:hover::before, .page dt.rtbn a:hover::after {
        background-color: #BBC555; }
      .page dt.rtbn a:hover::before {
        width: calc(100% + 35px); }
      .page dt.rtbn a:hover::after {
        right: -31px; } }

@media screen and (max-width: 768px) {
  .mo_use {
    display: block; }
  .pc_use {
    display: none; }
  .top_btn {
    right: 20px; }
  right: 20px; }

@media (max-width: 767px) {
  .page {
    font-size: 1.0625rem;
    white-space: nowrap;
    margin-top: 54px; }
    .page dd {
      width: 12%; }
    .page dt.ltbn a {
      margin-right: 0.875rem; }
      .page dt.ltbn a:before {
        border-width: 5px 5px 5px 0; }
    .page dt.rtbn a {
      margin-left: 0.875rem; }
      .page dt.rtbn a:before {
        border-width: 5px 0 5px 5px; }
    .page dt.ltbn a,
    .page dt.rtbn a {
      width: 32px;
      height: 28px; }
  .back_btn,
  .more_btn {
    font-size: 1.0625rem; }
    .back_btn a,
    .more_btn a {
      padding: 12.5px 25px;
      border-radius: 4px; } }

@media (max-width: 330px) {
  .page dd {
    width: 11%; } }

/*----------------------header------------------------*/
.header_show {
  transition: .4S;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(19, 19, 20, 0);
  z-index: 10; }
  .header_show li {
    list-style: none; }
  .header_show.active {
    background: #131314; }

.header_box {
  position: relative;
  width: 100%;
  height: 98px;
  padding: 0 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  background: #fff;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); }
  @media (max-width: 1199px) {
    .header_box {
      padding: 0 40px; } }
  @media (max-width: 991px) {
    .header_box {
      padding: 0 25px; } }

.logo {
  width: 166px;
  height: 52px;
  background: url(../images/logo.png) top left no-repeat;
  background-size: contain;
  text-indent: -10000px; }
  @media (max-width: 991px) {
    .logo {
      width: 120px;
      height: 42px; } }
  .logo a {
    width: 100%;
    height: 100%;
    display: block; }
  .logo h1 {
    display: none; }

/*----------------------nav------------------------*/
.nav_box {
  position: relative;
  display: flex;
  align-items: center; }
  .nav_box .link-bx {
    margin-left: 35px;
    display: flex; }
    .nav_box .link-bx a {
      display: block;
      width: 40px;
      position: relative;
      transition: .4s ease; }
      .nav_box .link-bx a:not(:last-child) {
        margin-right: 15px; }
      .nav_box .link-bx a img {
        transition: .4s ease; }
      .nav_box .link-bx a .hov {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0;
        left: 0;
        opacity: 0; }
      @media (min-width: 768px) {
        .nav_box .link-bx a:hover .unhov {
          opacity: 0; }
        .nav_box .link-bx a:hover .hov {
          opacity: 1; } }
  .nav_box ul {
    display: flex; }
    .nav_box ul li.active a {
      color: #BBC555; }
    .nav_box ul li {
      position: relative;
      text-align: center;
      width: auto; }
      .nav_box ul li::before {
        transition: .4s;
        position: absolute;
        content: '';
        width: 1px;
        height: 69%;
        top: 5px;
        right: -20px;
        background: #6A6A6A; }
      .nav_box ul li:nth-child(4)::before {
        display: none; }
      @media (min-width: 768px) {
        .nav_box ul li a:hover {
          color: #BBC555; } }
    .nav_box ul li:not(:last-child) {
      margin-right: 40px; }
  .nav_box a {
    width: 100%;
    display: block;
    color: #6A6A6A;
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: 1.5px;
    font-weight: 300;
    position: relative; }
    .nav_box a:hover .en {
      opacity: 0; }
    .nav_box a:hover .tw {
      opacity: 1; }

/*----------------------nav icon------------------------*/
.menu-wrapper {
  display: none; }

.menu-wrapper {
  display: none;
  position: absolute;
  top: 0;
  right: 25px;
  bottom: 0;
  margin: auto;
  width: 41px;
  height: 30px;
  cursor: pointer;
  z-index: 10; }

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 41px;
  height: 2px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em; }

.hamburger-menu {
  position: relative;
  transform: translateY(14px);
  background: #BBC555;
  transition: all 0ms 300ms;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }
  .hamburger-menu.animate {
    background: rgba(255, 255, 255, 0); }

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 14px;
  background: #BBC555;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 14px;
  background: #BBC555;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(42deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-42deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.menu-icon {
  display: none;
  top: 45%;
  right: -0.7em;
  z-index: 100;
  width: 45px;
  height: 39px;
  margin-top: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: absolute; }
  @media (max-width: 768px) {
    .menu-icon {
      display: block; } }

.menu-icon-block {
  display: none;
  position: relative;
  height: 100%;
  margin: auto; }
  .menu-icon-block i {
    position: absolute;
    display: block;
    margin: auto;
    width: 72%;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
    .menu-icon-block i.i1 {
      top: 0%; }
    .menu-icon-block i.i2 {
      top: 25%; }
    .menu-icon-block i.i3 {
      top: 51%; }

@media (max-width: 767px) {
  .logo {
    width: 120px;
    height: 37px; }
  .header_box {
    width: 100%;
    height: 60px;
    font-size: 1.0625rem;
    margin: 0 auto;
    padding: 0 25px; }
  .nav_box nav {
    width: 100%;
    margin: auto; }
  .nav_box {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(110, 110, 110, 0.95); }
    .nav_box a {
      font-size: 1.25rem; }
    .nav_box ul {
      display: block;
      margin-top: 55px; }
      .nav_box ul li {
        width: 100%;
        position: relative; }
        .nav_box ul li::before {
          width: 20px;
          height: 1px;
          background: #fff;
          bottom: 0;
          top: auto;
          right: auto;
          left: 50%;
          transform: translateX(-50%); }
        .nav_box ul li a {
          padding: 30px 15px;
          text-align: center;
          text-decoration: none;
          color: #fff; }
    .nav_box ul > li:not(:last-child) {
      margin-right: 0; }
  .nav_box .link-bx {
    justify-content: center;
    margin-left: 0;
    margin-top: 15%; }
    .nav_box .link-bx a {
      margin: 0 20px; }
      .nav_box .link-bx a:not(:last-child) {
        margin-right: 0px; }
      .nav_box .link-bx a .unhov {
        opacity: 0; }
      .nav_box .link-bx a .hov {
        opacity: 1; }
  .menu-wrapper {
    display: block; } }

footer {
  z-index: -10;
  padding: 35px 145px 30px 110px;
  background-color: #BBC555; }
  @media (max-width: 1199px) {
    footer {
      padding: 35px 40px 30px 40px; } }
  @media (max-width: 991px) {
    footer {
      padding: 25px 25px 15px 25px; } }
  footer .f-obx {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991px) {
      footer .f-obx {
        display: block; } }
    footer .f-obx .tbx {
      order: 2;
      max-width: 665px; }
      @media (max-width: 991px) {
        footer .f-obx .tbx {
          margin-bottom: 45px;
          max-width: 100%; } }
      footer .f-obx .tbx ul {
        flex-wrap: wrap;
        display: flex; }
        @media (max-width: 991px) {
          footer .f-obx .tbx ul {
            display: block; } }
        footer .f-obx .tbx ul li {
          display: flex;
          font-family: "Noto Sans TC"; }
          footer .f-obx .tbx ul li:nth-child(1) {
            margin-right: 20px; }
          footer .f-obx .tbx ul li:nth-child(2) {
            margin-right: 20px; }
          footer .f-obx .tbx ul li span {
            font-size: 1rem;
            line-height: 2;
            letter-spacing: 1px;
            font-weight: 400;
            color: #71782E;
            padding-right: 10px;
            margin-right: 10px;
            position: relative; }
            @media (max-width: 991px) {
              footer .f-obx .tbx ul li span {
                font-size: 0.875rem;
                margin-right: 8px;
                padding-right: 8px;
                letter-spacing: 0; } }
            footer .f-obx .tbx ul li span::before {
              position: absolute;
              content: '';
              right: 0;
              top: 10px;
              width: 1px;
              height: 50%;
              background: #71782E; }
          footer .f-obx .tbx ul li a, footer .f-obx .tbx ul li div {
            font-size: 1rem;
            line-height: 2;
            letter-spacing: 1px;
            font-weight: 400;
            color: #fff; }
            @media (max-width: 991px) {
              footer .f-obx .tbx ul li a, footer .f-obx .tbx ul li div {
                font-size: 0.875rem;
                letter-spacing: 0; } }
      footer .f-obx .tbx .adr {
        font-size: 1rem;
        line-height: 1.3;
        letter-spacing: 1px;
        font-weight: 400;
        color: #fff;
        font-family: "Noto Sans TC"; }
        @media (max-width: 991px) {
          footer .f-obx .tbx .adr {
            font-size: 0.875rem;
            letter-spacing: 0; } }
    footer .f-obx .bbx {
      order: 1; }
      @media (max-width: 991px) {
        footer .f-obx .bbx .f-logo {
          width: 126px; } }
      footer .f-obx .bbx .copyright {
        font-size: 0.875rem;
        line-height: 1.3;
        letter-spacing: 1px;
        font-weight: 400;
        color: #71782E;
        margin-top: 12px;
        font-family: "Noto Sans TC"; }
        @media (max-width: 991px) {
          footer .f-obx .bbx .copyright {
            font-size: 0.75rem;
            letter-spacing: 0;
            margin-top: 6px; } }

/******************************************/
/*		pages
/******************************************/
@media (max-width: 767px) {
  .indexPage main {
    margin-top: 0; } }

.indexPage .banner-bx {
  width: 100%;
  height: calc(100vh - 98px); }
  @media (max-width: 991px) and (min-width: 768px) {
    .indexPage .banner-bx {
      height: auto; } }
  @media (max-width: 767px) and (min-width: 451px) {
    .indexPage .banner-bx {
      height: auto; } }
  @media (max-width: 450px) {
    .indexPage .banner-bx {
      height: 100vh; } }
  .indexPage .banner-bx .bgcover {
    height: calc(100vh - 98px);
    background-size: cover !important;
    background-position: center center !important; }
    @media (max-width: 991px) and (min-width: 768px) {
      .indexPage .banner-bx .bgcover {
        height: 0;
        padding-bottom: 51.1%; } }
    @media (max-width: 767px) and (min-width: 451px) {
      .indexPage .banner-bx .bgcover {
        height: 0;
        padding-bottom: 177.8%; } }
    @media (max-width: 450px) {
      .indexPage .banner-bx .bgcover {
        height: 100vh;
        padding-bottom: 0; } }
  .indexPage .banner-bx a {
    display: block; }
  .indexPage .banner-bx .slick-dots {
    text-align: center;
    right: 0; }
    @media (max-width: 767px) {
      .indexPage .banner-bx .slick-dots {
        text-align: center;
        right: auto; } }
    .indexPage .banner-bx .slick-dots li.slick-active button:before {
      background-color: #BBC555; }
    .indexPage .banner-bx .slick-dots li {
      width: 47px;
      height: 20px !important; }
      .indexPage .banner-bx .slick-dots li button {
        width: 47px;
        height: 20px !important;
        padding: 0; }
        .indexPage .banner-bx .slick-dots li button::before {
          width: 47px;
          height: 3px;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          border-radius: 0;
          background-color: #ffffff;
          transition: .4s; }

.indexPage .item1 {
  margin-top: 120px;
  padding-bottom: 140px; }
  @media (max-width: 767px) {
    .indexPage .item1 {
      margin-top: 60px;
      padding-bottom: 70px; } }
  .indexPage .item1 .it1list {
    margin-top: 60px;
    position: relative; }
    @media (max-width: 767px) {
      .indexPage .item1 .it1list {
        margin-top: 30px; } }
    .indexPage .item1 .it1list li {
      max-width: 610px;
      width: 100%;
      height: 265px;
      box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.4);
      position: relative; }
      @media (max-width: 767px) {
        .indexPage .item1 .it1list li {
          height: 147px;
          box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.4); } }
      .indexPage .item1 .it1list li .des {
        position: absolute;
        font-size: 1.875rem;
        line-height: 1.5;
        letter-spacing: 1px;
        font-weight: 400;
        color: #fff;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 313px;
        height: 45px;
        overflow: hidden; }
        @media (max-width: 767px) {
          .indexPage .item1 .it1list li .des {
            font-size: 1.125rem;
            width: 190px; } }
      .indexPage .item1 .it1list li .morebtn {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        width: 144px;
        height: 42px;
        border: 2px solid #fff;
        transition: .4s ease;
        font-size: 1.125rem;
        line-height: 2.2;
        letter-spacing: 0.25px;
        font-weight: 400;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        font-family: "Noto Sans TC"; }
        @media (max-width: 767px) {
          .indexPage .item1 .it1list li .morebtn {
            width: 90px;
            height: 26px;
            border-radius: 2px;
            font-size: 0.875rem;
            line-height: 1.6;
            bottom: 18px; } }
      .indexPage .item1 .it1list li:nth-child(odd) {
        margin-left: auto;
        margin-right: 0;
        background-image: linear-gradient(to bottom, #A1AA49, #BBC555 68%); }
        .indexPage .item1 .it1list li:nth-child(odd) .des {
          left: 40%; }
        @media (min-width: 768px) {
          .indexPage .item1 .it1list li:nth-child(odd) .morebtn:hover {
            background-color: #fff;
            color: #A1AA49; } }
      .indexPage .item1 .it1list li:nth-child(even) {
        background-image: linear-gradient(to bottom, #6E6E6E, #949494 74%); }
        .indexPage .item1 .it1list li:nth-child(even)::before {
          position: absolute;
          content: '';
          top: 30px;
          right: 0;
          width: calc(100% - 213px);
          height: 2px;
          background-color: #fff; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1list li:nth-child(even)::before {
              width: calc(100% - 103px);
              top: 17px;
              height: 1px; } }
        .indexPage .item1 .it1list li:nth-child(even)::after {
          position: absolute;
          content: '';
          left: 30px;
          bottom: 0;
          width: 2px;
          height: calc(100% - 175px);
          background-color: #fff; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1list li:nth-child(even)::after {
              left: 17px;
              height: calc(100% - 90px);
              width: 1px; } }
        .indexPage .item1 .it1list li:nth-child(even) .img-bx {
          position: absolute;
          top: 20px;
          left: 30px; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1list li:nth-child(even) .img-bx {
              width: 63px;
              top: 10px;
              left: 15px; } }
        .indexPage .item1 .it1list li:nth-child(even) .des {
          left: 60%; }
        @media (min-width: 768px) {
          .indexPage .item1 .it1list li:nth-child(even) .morebtn:hover {
            background-color: #fff;
            color: #6E6E6E; } }
      .indexPage .item1 .it1list li:nth-child(1) {
        z-index: 10; }
        .indexPage .item1 .it1list li:nth-child(1)::before {
          position: absolute;
          content: '';
          top: 30px;
          right: 0;
          width: calc(100% - 213px);
          height: 2px;
          background-color: #fff; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1list li:nth-child(1)::before {
              width: calc(100% - 103px);
              top: 17px;
              height: 1px;
              left: 0;
              right: auto; } }
        .indexPage .item1 .it1list li:nth-child(1)::after {
          position: absolute;
          content: '';
          left: 30px;
          bottom: 0;
          width: 2px;
          height: calc(100% - 175px);
          background-color: #fff; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1list li:nth-child(1)::after {
              right: 17px;
              left: auto;
              height: calc(100% - 90px);
              width: 1px; } }
        .indexPage .item1 .it1list li:nth-child(1) .img-bx {
          position: absolute;
          top: 20px;
          left: 30px; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1list li:nth-child(1) .img-bx {
              width: 63px;
              top: 17px;
              left: auto;
              right: 15px; } }
        .indexPage .item1 .it1list li:nth-child(1) .des {
          left: 60%; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1list li:nth-child(1) .des {
              left: 40%; } }
      .indexPage .item1 .it1list li:nth-child(2) {
        z-index: 9; }
        .indexPage .item1 .it1list li:nth-child(2) .img-bx {
          position: absolute;
          top: 20px;
          right: 30px; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1list li:nth-child(2) .img-bx {
              top: 10px;
              left: 15px; } }
      .indexPage .item1 .it1list li:nth-child(3) {
        z-index: 8; }
        .indexPage .item1 .it1list li:nth-child(3)::before {
          position: absolute;
          content: '';
          top: 30px;
          left: 0;
          width: calc(100% - 213px);
          height: 2px;
          background-color: #fff; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1list li:nth-child(3)::before {
              width: calc(100% - 103px);
              top: 17px;
              height: 1px;
              left: 0;
              right: auto; } }
        .indexPage .item1 .it1list li:nth-child(3)::after {
          position: absolute;
          content: '';
          right: 30px;
          bottom: 0;
          width: 2px;
          height: calc(100% - 175px);
          background-color: #fff; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1list li:nth-child(3)::after {
              right: 17px;
              left: auto;
              height: calc(100% - 90px);
              width: 1px; } }
        .indexPage .item1 .it1list li:nth-child(3) .img-bx {
          position: absolute;
          top: 20px;
          left: auto;
          right: 30px; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1list li:nth-child(3) .img-bx {
              width: 63px;
              top: 17px;
              left: auto;
              right: 15px; } }
      .indexPage .item1 .it1list li:nth-child(4) {
        z-index: 7; }
      .indexPage .item1 .it1list li:not(:first-child) {
        margin-top: -65px; }
        @media (max-width: 991px) {
          .indexPage .item1 .it1list li:not(:first-child) {
            margin-top: 70px; } }
        @media (max-width: 767px) {
          .indexPage .item1 .it1list li:not(:first-child) {
            margin-top: 35px; } }

.indexPage .item2 {
  padding-top: 75px;
  padding-bottom: 120px;
  background-color: #fff; }
  @media (max-width: 767px) {
    .indexPage .item2 {
      padding-top: 37px;
      padding-bottom: 60px; } }
  .indexPage .item2 .editor_Content {
    margin-top: 70px; }
    @media (max-width: 767px) {
      .indexPage .item2 .editor_Content {
        margin-top: 35px; } }

.indexPage .item3 {
  background-color: #fff;
  padding-bottom: 140px; }
  @media (max-width: 767px) {
    .indexPage .item3 {
      padding-bottom: 105px; } }
  .indexPage .item3 .sh-conbx {
    margin-top: 75px; }
    @media (max-width: 767px) {
      .indexPage .item3 .sh-conbx {
        margin-top: 25px; } }

.aboutPage .item1::before {
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  background: url(../images/cover.png) no-repeat center center/cover;
  width: 1920px;
  height: 500px;
  z-index: -1;
  top: -320px; }
  @media (max-width: 767px) {
    .aboutPage .item1::before {
      top: -80px;
      height: 200px;
      width: 100%; } }

.aboutPage .item3::before {
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  background: url(../images/cover.png) no-repeat center center/cover;
  width: 1920px;
  height: 500px;
  z-index: -1;
  bottom: -300px; }
  @media (max-width: 767px) {
    .aboutPage .item3::before {
      bottom: -70px;
      height: 200px;
      width: 100%; } }

.aboutPage .item1 .it1-bx {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 767px) {
    .aboutPage .item1 .it1-bx {
      display: block; } }
  .aboutPage .item1 .it1-bx .l {
    width: 41.5%;
    text-align: center;
    position: relative; }
    @media (max-width: 767px) {
      .aboutPage .item1 .it1-bx .l {
        width: 100%;
        margin-bottom: 55px; } }
    .aboutPage .item1 .it1-bx .l .en-ti {
      font-size: 2rem;
      line-height: 1.4;
      letter-spacing: 0.25px;
      font-weight: 400;
      color: #BBC555; }
      @media (max-width: 767px) {
        .aboutPage .item1 .it1-bx .l .en-ti {
          font-size: 1.5rem;
          margin-bottom: 4px; } }
    .aboutPage .item1 .it1-bx .l .ti {
      font-size: 2.5rem;
      line-height: 1.3;
      letter-spacing: 0.25px;
      font-weight: 700;
      color: #000;
      font-family: "Noto Serif TC"; }
      @media (max-width: 767px) {
        .aboutPage .item1 .it1-bx .l .ti {
          font-size: 1.875rem; } }
  .aboutPage .item1 .it1-bx .r {
    width: 60%;
    font-size: 1.125rem;
    line-height: 1.9;
    letter-spacing: 0.35px;
    font-weight: 400;
    color: #686868;
    padding-left: 10%;
    position: relative; }
    .aboutPage .item1 .it1-bx .r::before {
      position: absolute;
      content: '';
      left: 0;
      top: 6px;
      height: 90%;
      width: 1px;
      background-color: #686868; }
      @media (max-width: 767px) {
        .aboutPage .item1 .it1-bx .r::before {
          right: auto;
          left: 50%;
          transform: translateX(-50%);
          top: -30px;
          bottom: auto;
          width: 63px;
          height: 1px; } }
    @media (max-width: 767px) {
      .aboutPage .item1 .it1-bx .r {
        width: 100%;
        padding-left: 0;
        line-height: 1.7; } }

.aboutPage .item2 {
  display: flex;
  align-items: center;
  margin-top: 75px;
  padding-top: 120px;
  position: relative;
  margin-bottom: 175px; }
  @media (max-width: 991px) {
    .aboutPage .item2 {
      display: block;
      padding-top: 45px;
      margin-bottom: 45px; } }
  .aboutPage .item2 .it2-bg {
    position: absolute;
    top: 0;
    right: 90px; }
    @media (max-width: 991px) {
      .aboutPage .item2 .it2-bg {
        right: 15px;
        width: 91%; } }
  .aboutPage .item2 .l {
    width: 50%;
    position: relative;
    z-index: 5; }
    @media (max-width: 991px) {
      .aboutPage .item2 .l {
        width: 100%; } }
    .aboutPage .item2 .l .bgcover {
      width: 100%;
      padding-bottom: 72.8%;
      background-size: cover !important; }
  .aboutPage .item2 .r {
    width: 50%;
    height: 0;
    padding-bottom: 36.4%;
    position: relative;
    background-image: linear-gradient(to right, #BBC555, #B2BC51, #E7F556);
    box-shadow: 0 3px 11px 4px rgba(0, 0, 0, 0.2); }
    @media (max-width: 991px) {
      .aboutPage .item2 .r {
        width: 100%;
        padding-bottom: 30px;
        height: auto;
        background-image: linear-gradient(to top, #BBC555, #B2BC51, #E7F556);
        box-shadow: 0 3px 11px 4px rgba(0, 0, 0, 0.2); } }
    .aboutPage .item2 .r .card {
      position: absolute;
      width: 75%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      @media (max-width: 1199px) {
        .aboutPage .item2 .r .card {
          width: 85%; } }
      @media (max-width: 991px) {
        .aboutPage .item2 .r .card {
          position: relative;
          top: auto;
          left: auto;
          transform: none;
          width: 90%;
          margin: 0 auto; } }
      .aboutPage .item2 .r .card .t-bx {
        padding-bottom: 3vw;
        margin-bottom: 3vw;
        border-bottom: 1px solid #000000; }
        @media (max-width: 1199px) {
          .aboutPage .item2 .r .card .t-bx {
            padding-bottom: 1vw;
            margin-bottom: 1vw; } }
        @media (max-width: 991px) {
          .aboutPage .item2 .r .card .t-bx {
            padding-top: 27px;
            padding-bottom: 40px;
            margin-bottom: 25px; } }
        .aboutPage .item2 .r .card .t-bx ul {
          display: flex;
          align-items: flex-end;
          justify-content: space-between; }
          @media (max-width: 991px) {
            .aboutPage .item2 .r .card .t-bx ul {
              display: block; } }
          .aboutPage .item2 .r .card .t-bx ul li {
            width: auto;
            text-align: center; }
            @media (max-width: 991px) {
              .aboutPage .item2 .r .card .t-bx ul li {
                width: 100%; }
                .aboutPage .item2 .r .card .t-bx ul li:not(:last-child) {
                  margin-bottom: 70px; } }
            .aboutPage .item2 .r .card .t-bx ul li .des {
              font-size: 1.25rem;
              line-height: 1.5;
              letter-spacing: 1.25px;
              font-weight: 700;
              color: #000000;
              margin-top: 39px; }
              @media (max-width: 1199px) {
                .aboutPage .item2 .r .card .t-bx ul li .des {
                  margin-top: 8px; } }
              @media (max-width: 991px) {
                .aboutPage .item2 .r .card .t-bx ul li .des {
                  font-size: 1.125rem;
                  margin-top: 14px; } }
            @media (max-width: 1199px) {
              .aboutPage .item2 .r .card .t-bx ul li .img-bx {
                width: 50%;
                margin: 0 auto; } }
            @media (max-width: 991px) {
              .aboutPage .item2 .r .card .t-bx ul li .img-bx {
                width: 25%; } }
      .aboutPage .item2 .r .card .b-bx .des {
        font-size: 1.125rem;
        line-height: 1.9;
        letter-spacing: 1.25px;
        font-weight: 400;
        color: #000000; }
        @media (max-width: 991px) {
          .aboutPage .item2 .r .card .b-bx .des {
            line-height: 1.7; } }

.aboutPage .item3 {
  margin-bottom: 180px; }
  @media (max-width: 767px) {
    .aboutPage .item3 {
      margin-bottom: 105px; } }
  .aboutPage .item3 .it3-list {
    margin-top: 70px;
    width: calc(100% + 20px);
    transform: translateX(-10px);
    user-select: auto; }
    @media (max-width: 1199px) {
      .aboutPage .item3 .it3-list {
        width: calc(85% + 20px);
        margin: 0 auto;
        transform: none; } }
    @media (max-width: 767px) {
      .aboutPage .item3 .it3-list {
        margin-top: 45px;
        width: calc(78% + 20px); } }
    .aboutPage .item3 .it3-list li {
      position: relative;
      padding-bottom: 120px;
      background-color: #fff;
      box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.3);
      margin: 5px 10px;
      transition: .4s ease; }
      @media (min-width: 768px) {
        .aboutPage .item3 .it3-list li:hover {
          background-color: #F2F2F2; } }
      @media (max-width: 767px) {
        .aboutPage .item3 .it3-list li {
          padding-bottom: 90px; } }
    .aboutPage .item3 .it3-list .img-bx {
      width: 100%; }
      @media (max-width: 767px) {
        .aboutPage .item3 .it3-list .img-bx {
          height: 235px;
          overflow: hidden; } }
      .aboutPage .item3 .it3-list .img-bx img {
        width: 100%; }
    .aboutPage .item3 .it3-list .des-bx {
      padding: 0 25px; }
      @media (max-width: 767px) {
        .aboutPage .item3 .it3-list .des-bx {
          padding: 0 20px; } }
    .aboutPage .item3 .it3-list .ti {
      font-size: 2.1875rem;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 700;
      color: #000000;
      margin-bottom: 10px;
      margin-top: 25px; }
      @media (max-width: 767px) {
        .aboutPage .item3 .it3-list .ti {
          font-size: 1.5rem;
          margin-top: 22px;
          margin-bottom: 3px; } }
    .aboutPage .item3 .it3-list .des {
      font-size: 1.125rem;
      line-height: 1.8;
      letter-spacing: 1px;
      font-weight: 400;
      color: #686868;
      height: 220px;
      overflow: hidden; }
      @media (max-width: 767px) {
        .aboutPage .item3 .it3-list .des {
          font-size: 0.875rem;
          height: 172px; } }
    .aboutPage .item3 .it3-list .wo-pos {
      position: absolute;
      bottom: 25px;
      left: 25px;
      font-size: 1.25rem;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 700;
      color: #000000;
      width: calc(100% - 50px); }
      @media (max-width: 767px) {
        .aboutPage .item3 .it3-list .wo-pos {
          font-size: 0.875rem;
          width: calc(100% - 40px);
          left: 20px; } }
      .aboutPage .item3 .it3-list .wo-pos::before {
        position: absolute;
        content: '';
        top: -10px;
        width: 100%;
        height: 1px;
        background-color: #686868; }
    .aboutPage .item3 .it3-list .slick-dots {
      display: none !important; }
    .aboutPage .item3 .it3-list .slick-prev, .aboutPage .item3 .it3-list .slick-next {
      width: 65px;
      height: 35px; }
      @media (max-width: 767px) {
        .aboutPage .item3 .it3-list .slick-prev, .aboutPage .item3 .it3-list .slick-next {
          width: 25px; } }
    .aboutPage .item3 .it3-list .slick-prev {
      background: url(../images/PREV.png) no-repeat right center/40px;
      left: -85px;
      padding-left: 25px; }
      @media (max-width: 1199px) {
        .aboutPage .item3 .it3-list .slick-prev {
          left: -70px; } }
      @media (max-width: 767px) {
        .aboutPage .item3 .it3-list .slick-prev {
          background: none;
          left: -25px; } }
      .aboutPage .item3 .it3-list .slick-prev::before {
        position: absolute;
        content: '';
        right: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        transition: .4s ease;
        background-color: #686868; }
        @media (max-width: 767px) {
          .aboutPage .item3 .it3-list .slick-prev::before {
            height: 1px; } }
      .aboutPage .item3 .it3-list .slick-prev::after {
        position: absolute;
        content: '';
        left: 4px;
        bottom: -1px;
        width: 2px;
        height: 13px;
        background-color: #686868;
        transition: .4s ease;
        transform: rotate(45deg); }
        @media (max-width: 767px) {
          .aboutPage .item3 .it3-list .slick-prev::after {
            width: 1px;
            left: 2px;
            height: 7px; } }
      @media (min-width: 768px) {
        .aboutPage .item3 .it3-list .slick-prev:hover::before, .aboutPage .item3 .it3-list .slick-prev:hover::after {
          background-color: #BBC555; }
        .aboutPage .item3 .it3-list .slick-prev:hover::before {
          width: calc(100% + 25px); }
        .aboutPage .item3 .it3-list .slick-prev:hover::after {
          left: -21px; } }
    .aboutPage .item3 .it3-list .slick-next {
      background: url(../images/NEXT.png) no-repeat left center/40px;
      right: -85px; }
      @media (max-width: 1199px) {
        .aboutPage .item3 .it3-list .slick-next {
          right: -70px; } }
      @media (max-width: 767px) {
        .aboutPage .item3 .it3-list .slick-next {
          background: none;
          right: -25px; } }
      .aboutPage .item3 .it3-list .slick-next::before {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        transition: .4s ease;
        background-color: #686868; }
        @media (max-width: 767px) {
          .aboutPage .item3 .it3-list .slick-next::before {
            height: 1px; } }
      .aboutPage .item3 .it3-list .slick-next::after {
        position: absolute;
        content: '';
        right: 4px;
        bottom: -1px;
        width: 2px;
        height: 13px;
        background-color: #686868;
        transition: .4s ease;
        transform: rotate(-45deg); }
        @media (max-width: 767px) {
          .aboutPage .item3 .it3-list .slick-next::after {
            width: 1px;
            right: 2px;
            height: 7px; } }
      @media (min-width: 768px) {
        .aboutPage .item3 .it3-list .slick-next:hover::before, .aboutPage .item3 .it3-list .slick-next:hover::after {
          background-color: #BBC555; }
        .aboutPage .item3 .it3-list .slick-next:hover::before {
          width: calc(100% + 25px); }
        .aboutPage .item3 .it3-list .slick-next:hover::after {
          right: -21px; } }

.servicePage .item1::before {
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  background: url(../images/cover.png) no-repeat center center/cover;
  width: 1920px;
  height: 500px;
  z-index: -1;
  top: -320px; }
  @media (max-width: 767px) {
    .servicePage .item1::before {
      top: -80px;
      height: 200px;
      width: 100%; } }

.servicePage .item3::before {
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  background: url(../images/cover.png) no-repeat center center/cover;
  width: 1920px;
  height: 500px;
  z-index: -1;
  bottom: -140px; }
  @media (max-width: 767px) {
    .servicePage .item3::before {
      bottom: -15px;
      height: 200px;
      width: 100%; } }

.servicePage .item1 {
  background: #fff; }

.servicePage .item2 {
  padding-top: 40px;
  background: #fff; }
  @media (max-width: 991px) {
    .servicePage .item2 {
      padding-top: 0px;
      margin-top: -20px; } }

.servicePage .item3 {
  padding-top: 100px;
  margin-bottom: 130px;
  background: #fff; }
  @media (max-width: 991px) {
    .servicePage .item3 {
      padding-top: 25px;
      margin-bottom: 85px; } }
  .servicePage .item3 .it3-list li {
    position: relative; }
    .servicePage .item3 .it3-list li:not(:last-child) a {
      padding-bottom: 50px;
      margin-bottom: 48px;
      border-bottom: 1px solid #C8C8C8; }
      @media (max-width: 991px) {
        .servicePage .item3 .it3-list li:not(:last-child) a {
          padding-bottom: 60px; } }
    @media (max-width: 991px) {
      .servicePage .item3 .it3-list li {
        padding-bottom: 0px; } }
    .servicePage .item3 .it3-list li a {
      display: flex;
      justify-content: space-between;
      position: relative;
      align-items: center; }
      @media (max-width: 991px) {
        .servicePage .item3 .it3-list li a {
          display: block;
          padding-bottom: 60px; } }
    .servicePage .item3 .it3-list li .l {
      width: 45%; }
      @media (max-width: 991px) {
        .servicePage .item3 .it3-list li .l {
          width: 100%; } }
      .servicePage .item3 .it3-list li .l .bgcover {
        padding-bottom: 53.6%;
        background-size: cover !important; }
    .servicePage .item3 .it3-list li .r {
      width: 49.4%; }
      @media (max-width: 991px) {
        .servicePage .item3 .it3-list li .r {
          width: 100%; } }
      .servicePage .item3 .it3-list li .r .group {
        font-size: 1.5rem;
        line-height: 1.4;
        letter-spacing: 1px;
        font-weight: 400;
        color: #BBC555;
        padding-bottom: 24px;
        margin-bottom: 22px;
        position: relative;
        font-family: "Noto Sans TC"; }
        @media (max-width: 991px) {
          .servicePage .item3 .it3-list li .r .group {
            margin-top: 15px;
            padding-bottom: 13px;
            margin-bottom: 12px; } }
        .servicePage .item3 .it3-list li .r .group::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 25px;
          height: 6px;
          background-color: #BBC555; }
          @media (max-width: 991px) {
            .servicePage .item3 .it3-list li .r .group::before {
              height: 3px;
              width: 14px; } }
      .servicePage .item3 .it3-list li .r .des {
        font-size: 1.125rem;
        line-height: 2;
        letter-spacing: 1px;
        font-weight: 400;
        color: #686868;
        font-family: "Noto Sans TC"; }
    .servicePage .item3 .it3-list li .more-btn {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1.125rem;
      line-height: 2;
      letter-spacing: 1px;
      font-weight: 400;
      color: #686868;
      font-family: "Noto Sans TC"; }
      @media (max-width: 991px) {
        .servicePage .item3 .it3-list li .more-btn {
          top: auto;
          bottom: 25px;
          font-size: 0.875rem;
          padding-right: 6px; } }
      .servicePage .item3 .it3-list li .more-btn::before {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: calc(100% + 15px);
        height: 2px;
        transition: .4s ease;
        background-color: #686868; }
        @media (max-width: 991px) {
          .servicePage .item3 .it3-list li .more-btn::before {
            width: 100%; } }
        @media (max-width: 767px) {
          .servicePage .item3 .it3-list li .more-btn::before {
            height: 1px; } }
      .servicePage .item3 .it3-list li .more-btn::after {
        position: absolute;
        content: '';
        right: -11px;
        bottom: -1px;
        width: 2px;
        height: 13px;
        background-color: #686868;
        transition: .4s ease;
        transform: rotate(-45deg); }
        @media (max-width: 767px) {
          .servicePage .item3 .it3-list li .more-btn::after {
            width: 1px;
            right: 2px;
            height: 7px; } }
    @media (min-width: 768px) {
      .servicePage .item3 .it3-list li:hover .more-btn::before, .servicePage .item3 .it3-list li:hover .more-btn::after {
        background-color: #BBC555; }
      .servicePage .item3 .it3-list li:hover .more-btn::before {
        width: calc(100% + 35px); }
      .servicePage .item3 .it3-list li:hover .more-btn::after {
        right: -30px; } }
  .servicePage .item3 .page {
    margin-top: 140px; }
    @media (max-width: 991px) {
      .servicePage .item3 .page {
        margin-top: 45px; } }

.servicedetailPage .item1 {
  background-color: #fff; }
  .servicedetailPage .item1::before {
    position: absolute;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    background: url(../images/cover.png) no-repeat center center/cover;
    width: 1920px;
    height: 500px;
    z-index: -1;
    top: -320px; }
    @media (max-width: 767px) {
      .servicedetailPage .item1::before {
        top: -80px;
        height: 200px;
        width: 100%; } }

.servicedetailPage .item2 {
  background-color: #fff; }
  .servicedetailPage .item2::before {
    position: absolute;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    background: url(../images/cover.png) no-repeat center center/cover;
    width: 1920px;
    height: 500px;
    z-index: -1;
    bottom: -140px; }
    @media (max-width: 767px) {
      .servicedetailPage .item2::before {
        bottom: -15px;
        height: 200px;
        width: 100%; } }

.servicedetailPage .item2 {
  padding-top: 40px;
  margin-bottom: 140px; }
  @media (max-width: 991px) {
    .servicedetailPage .item2 {
      padding-top: 0;
      margin-top: -20px;
      margin-bottom: 90px; } }
  .servicedetailPage .item2 .ti {
    font-size: 1.5rem;
    line-height: 1.5;
    letter-spacing: 1px;
    font-weight: 500;
    color: #000000;
    font-family: "Noto Sans TC";
    margin-bottom: 58px; }
    @media (max-width: 991px) {
      .servicedetailPage .item2 .ti {
        margin-bottom: 29px; } }
  .servicedetailPage .item2 .back-btn {
    position: relative;
    display: block;
    width: 78px;
    height: 30px;
    margin: 0 auto;
    padding-left: 24px;
    margin-top: 50px;
    font-family: "Noto Sans TC";
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: 1px;
    font-weight: 400;
    color: #686868; }
    @media (max-width: 767px) {
      .servicedetailPage .item2 .back-btn {
        margin-top: 25px;
        font-size: 0.875rem;
        width: 64px;
        height: 24px; } }
    .servicedetailPage .item2 .back-btn::before {
      position: absolute;
      content: '';
      right: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      transition: .4s ease;
      background-color: #686868; }
      @media (max-width: 767px) {
        .servicedetailPage .item2 .back-btn::before {
          height: 1px; } }
    .servicedetailPage .item2 .back-btn::after {
      position: absolute;
      content: '';
      left: 4px;
      bottom: -1px;
      width: 2px;
      height: 13px;
      background-color: #686868;
      transition: .4s ease;
      transform: rotate(45deg); }
      @media (max-width: 767px) {
        .servicedetailPage .item2 .back-btn::after {
          width: 1px;
          left: 2px;
          height: 7px; } }
    @media (min-width: 768px) {
      .servicedetailPage .item2 .back-btn:hover::before, .servicedetailPage .item2 .back-btn:hover::after {
        background-color: #BBC555; }
      .servicedetailPage .item2 .back-btn:hover::before {
        width: calc(100% + 25px); }
      .servicedetailPage .item2 .back-btn:hover::after {
        left: -21px; } }

.casePage .item1::before {
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  background: url(../images/cover.png) no-repeat center center/cover;
  width: 1920px;
  height: 500px;
  z-index: -1;
  top: -320px; }
  @media (max-width: 767px) {
    .casePage .item1::before {
      top: -80px;
      height: 200px;
      width: 100%; } }

.casePage .item1::after {
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  background: url(../images/cover.png) no-repeat center center/cover;
  width: 1920px;
  height: 500px;
  z-index: -1;
  bottom: -140px; }
  @media (max-width: 767px) {
    .casePage .item1::after {
      bottom: -15px;
      height: 200px;
      width: 100%; } }

.casePage .item1 {
  margin-bottom: 125px;
  background-color: #fff; }
  @media (max-width: 767px) {
    .casePage .item1 {
      margin-top: 66px; } }
  .casePage .item1 .sh-conbx {
    margin-top: 125px; }
    @media (max-width: 767px) {
      .casePage .item1 .sh-conbx {
        margin-top: 66px; } }

.conPage .item1::before {
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  background: url(../images/cover.png) no-repeat center center/cover;
  width: 1920px;
  height: 500px;
  z-index: -1;
  top: -320px; }
  @media (max-width: 767px) {
    .conPage .item1::before {
      top: -80px;
      height: 200px;
      width: 100%; } }

.conPage .item2::before {
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  background: url(../images/cover.png) no-repeat center center/cover;
  width: 1920px;
  height: 500px;
  z-index: -1;
  bottom: -140px; }
  @media (max-width: 767px) {
    .conPage .item2::before {
      bottom: -15px;
      height: 200px;
      width: 100%; } }

.conPage .item1 .ti {
  font-size: 1.875rem;
  line-height: 1.3;
  letter-spacing: 0.3px;
  font-weight: 700;
  color: #000000; }
  @media (max-width: 767px) {
    .conPage .item1 .ti {
      font-size: 1.5rem; } }

.conPage .item1 .en-ti {
  font-size: 1.5rem;
  line-height: 1.4;
  letter-spacing: 0.25px;
  font-weight: 400;
  color: #BBC555;
  font-family: "Noto Sans TC"; }
  @media (max-width: 767px) {
    .conPage .item1 .en-ti {
      font-size: 0.875rem; } }

.conPage .item1 .flex-bx {
  display: flex;
  margin-top: 30px; }
  @media (max-width: 991px) {
    .conPage .item1 .flex-bx {
      display: block; } }
  @media (max-width: 767px) {
    .conPage .item1 .flex-bx {
      margin-top: 20px; } }
  .conPage .item1 .flex-bx a, .conPage .item1 .flex-bx .fax, .conPage .item1 .flex-bx .adr {
    font-size: 1.25rem;
    line-height: 2;
    letter-spacing: 0px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 13px;
    display: block;
    font-family: "Noto Sans TC"; }
    @media (max-width: 767px) {
      .conPage .item1 .flex-bx a, .conPage .item1 .flex-bx .fax, .conPage .item1 .flex-bx .adr {
        font-size: 0.9375rem;
        margin-bottom: 8px; } }
    .conPage .item1 .flex-bx a span, .conPage .item1 .flex-bx .fax span, .conPage .item1 .flex-bx .adr span {
      position: relative;
      padding-left: 48px; }
      @media (max-width: 767px) {
        .conPage .item1 .flex-bx a span, .conPage .item1 .flex-bx .fax span, .conPage .item1 .flex-bx .adr span {
          padding-left: 42px; } }
      .conPage .item1 .flex-bx a span img, .conPage .item1 .flex-bx .fax span img, .conPage .item1 .flex-bx .adr span img {
        position: absolute;
        left: 0;
        top: -7px; }
        @media (max-width: 767px) {
          .conPage .item1 .flex-bx a span img, .conPage .item1 .flex-bx .fax span img, .conPage .item1 .flex-bx .adr span img {
            min-width: 32px;
            width: 32px; } }
  .conPage .item1 .flex-bx .fax span img {
    left: 4px; }
    @media (max-width: 767px) {
      .conPage .item1 .flex-bx .fax span img {
        min-width: 26px;
        width: 26px;
        top: -3px;
        left: 2px; } }
  .conPage .item1 .flex-bx .l {
    width: 40%; }
    @media (max-width: 991px) {
      .conPage .item1 .flex-bx .l {
        width: 100%; } }
  .conPage .item1 .flex-bx .r {
    width: 60%; }
    @media (max-width: 991px) {
      .conPage .item1 .flex-bx .r {
        margin-top: -4px;
        width: 100%; } }
    @media (max-width: 767px) {
      .conPage .item1 .flex-bx .r {
        margin-top: 12px; } }

.conPage .item1 .map-bx {
  margin-top: 30px; }
  @media (max-width: 767px) {
    .conPage .item1 .map-bx {
      margin-top: 15px; } }
  .conPage .item1 .map-bx iframe {
    height: 380px;
    border: 1px solid #C8C8C8; }
    @media (max-width: 767px) {
      .conPage .item1 .map-bx iframe {
        height: 195px; } }

.conPage .item2 {
  margin-bottom: 120px;
  margin-top: 140px;
  padding-top: 95px;
  position: relative; }
  @media (max-width: 991PX) {
    .conPage .item2 {
      margin-top: 0; } }
  .conPage .item2 .container {
    position: relative; }
  .conPage .item2 .it2-bg {
    position: absolute;
    top: -82px;
    right: -66px;
    width: 55%; }
    @media (max-width: 991PX) {
      .conPage .item2 .it2-bg {
        top: -10%;
        width: calc(100% - 50px);
        left: 50%;
        right: auto;
        transform: translateX(-50%); } }
    @media (max-width: 767px) {
      .conPage .item2 .it2-bg {
        top: -47px; } }
  .conPage .item2 .it2-bx {
    padding: 65px 10%;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3); }
    @media (max-width: 991PX) {
      .conPage .item2 .it2-bx {
        padding: 60px 25px;
        padding-bottom: 50px;
        box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.3); } }
    .conPage .item2 .it2-bx .top-ti {
      font-size: 1.25rem;
      line-height: 1.75;
      letter-spacing: 1px;
      font-weight: 400;
      color: #000000;
      padding-bottom: 5px;
      border-bottom: 1px solid #C8C8C8;
      position: relative; }
      @media (max-width: 991PX) {
        .conPage .item2 .it2-bx .top-ti {
          font-size: 0.9375rem; } }
      .conPage .item2 .it2-bx .top-ti::before {
        position: absolute;
        content: '';
        bottom: -9px;
        right: 0;
        border-top: 9px solid #c8c8c8;
        border-left: 7px dashed transparent;
        border-right: 7px dashed transparent; }
        @media (max-width: 991PX) {
          .conPage .item2 .it2-bx .top-ti::before {
            border-top: 5px solid #c8c8c8;
            border-left: 4px dashed transparent;
            border-right: 4px dashed transparent;
            bottom: -6px; } }
    .conPage .item2 .it2-bx .form-bx {
      margin-top: 50px; }
      @media (max-width: 991PX) {
        .conPage .item2 .it2-bx .form-bx {
          margin-top: 28px; } }
      .conPage .item2 .it2-bx .form-bx .flex-bx {
        display: flex;
        justify-content: space-between; }
        @media (max-width: 991PX) {
          .conPage .item2 .it2-bx .form-bx .flex-bx {
            display: block; } }
        .conPage .item2 .it2-bx .form-bx .flex-bx .form-group {
          width: 32%; }
          @media (max-width: 991PX) {
            .conPage .item2 .it2-bx .form-bx .flex-bx .form-group {
              width: 100%;
              margin-bottom: 25px; } }
      .conPage .item2 .it2-bx .form-bx label {
        font-size: 1.25rem;
        line-height: 2;
        letter-spacing: 1px;
        font-weight: 400;
        color: #000000; }
        .conPage .item2 .it2-bx .form-bx label span {
          color: #DC0000; }
        @media (max-width: 991px) {
          .conPage .item2 .it2-bx .form-bx label {
            font-size: 0.9375rem; } }
      .conPage .item2 .it2-bx .form-bx input {
        height: 55px;
        background-color: #F2F2F2;
        padding-left: 20px;
        border-radius: 0;
        border: none; }
        .conPage .item2 .it2-bx .form-bx input:focus {
          border: none; }
        @media (max-width: 991px) {
          .conPage .item2 .it2-bx .form-bx input {
            height: 37px; } }
      .conPage .item2 .it2-bx .form-bx .help-block {
        font-size: 1rem;
        line-height: 2;
        letter-spacing: 1px;
        font-weight: 400;
        color: #DC0000;
        height: 32px; }
        @media (max-width: 991px) {
          .conPage .item2 .it2-bx .form-bx .help-block {
            font-size: 0.75rem; } }
      .conPage .item2 .it2-bx .form-bx .form-textbx .textarea-wrapper {
        height: 250px;
        overflow: hidden;
        border: none;
        padding: 0px 0px 10px;
        margin-top: 0px;
        background-color: #F2F2F2; }
        @media (max-width: 991px) {
          .conPage .item2 .it2-bx .form-bx .form-textbx .textarea-wrapper {
            margin-top: 0; } }
        @media (max-width: 767px) {
          .conPage .item2 .it2-bx .form-bx .form-textbx .textarea-wrapper {
            height: 125px; } }
        .conPage .item2 .it2-bx .form-bx .form-textbx .textarea-wrapper textarea {
          min-height: 237px;
          height: 0;
          overflow: hidden;
          border-bottom: none;
          padding-top: 14px;
          padding-left: 11px;
          text-rendering: auto;
          -webkit-writing-mode: horizontal-tb !important;
          background-color: #F2F2F2;
          resize: none;
          border: none;
          font-family: "Noto Sans TC";
          cursor: text; }
          @media (max-width: 767px) {
            .conPage .item2 .it2-bx .form-bx .form-textbx .textarea-wrapper textarea {
              min-height: 112px; } }
          .conPage .item2 .it2-bx .form-bx .form-textbx .textarea-wrapper textarea:focus {
            border: none; }
      .conPage .item2 .it2-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #BBC555;
        width: 6px;
        border-radius: 0; }
        @media (max-width: 991px) {
          .conPage .item2 .it2-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
            width: 3px; } }
      .conPage .item2 .it2-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerRail {
        background-color: #b4b4b4;
        width: 2px; }
        @media (max-width: 991px) {
          .conPage .item2 .it2-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerRail {
            width: 1px; } }
      .conPage .item2 .it2-bx .form-bx .form-textbx .mCSB_scrollTools {
        right: -5px; }
      .conPage .item2 .it2-bx .form-bx .form-textbx .mCSB_inside > .mCSB_container {
        margin-right: 13px; }
      .conPage .item2 .it2-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerContainer {
        top: 15px;
        left: -3px; }
        @media (max-width: 991px) {
          .conPage .item2 .it2-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerContainer {
            top: 6px; } }
      .conPage .item2 .it2-bx .form-bx .send-bx {
        text-align: center; }
        .conPage .item2 .it2-bx .form-bx .send-bx a {
          display: block;
          width: 252px;
          height: 72px;
          background-color: #BBC555;
          border: 1px solid #BBC555;
          margin: 0 auto;
          margin-top: 55px;
          font-family: "Noto Sans TC";
          font-size: 1.125rem;
          line-height: 4;
          letter-spacing: 0.25px;
          font-weight: 400;
          color: #fff; }
          @media (max-width: 991px) {
            .conPage .item2 .it2-bx .form-bx .send-bx a {
              width: 185px;
              height: 44px;
              font-size: 0.875rem;
              line-height: 3.2;
              margin-top: 35px; } }
          @media (min-width: 767px) {
            .conPage .item2 .it2-bx .form-bx .send-bx a:hover {
              background-color: #fff;
              color: #BBC555; } }

.bgcover {
  width: 100%;
  height: 0;
  background: 50%/cover; }

.mo {
  display: none; }
  @media (max-width: 767px) {
    .mo {
      display: block; } }

.pc {
  display: none; }
  @media (min-width: 768px) {
    .pc {
      display: block; } }

.container {
  padding: 0 16px;
  margin: 0 auto;
  width: 100%; }
  @media (max-width: 767px) {
    .container {
      padding: 0 25px; } }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1056px; } }

#top-menu-ul {
  position: relative;
  z-index: 1; }
  @media (max-width: 767px) {
    #top-menu-ul {
      margin: 0 auto 35px; } }
  @media (min-width: 768px) {
    #top-menu-ul {
      margin: 0 auto 50px; } }
  #top-menu-ul .item_Menu {
    margin: 0 auto;
    overflow: hidden;
    height: 56px; }
  #top-menu-ul .item_menu_Box {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    text-align: center; }
    #top-menu-ul .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: inline-flex;
      position: relative; }
      @media (min-width: 768px) {
        #top-menu-ul .item_menu_Box .item_menu_list {
          padding-left: 0 !important;
          padding-right: 0 !important; } }
      #top-menu-ul .item_menu_Box .item_menu_list::before {
        position: absolute;
        content: '';
        bottom: 12px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #C8C8C8;
        z-index: 3; }
        @media (max-width: 767px) {
          #top-menu-ul .item_menu_Box .item_menu_list::before {
            bottom: 19px; } }
    #top-menu-ul .item_menu_Box li {
      height: 56px; }
      #top-menu-ul .item_menu_Box li:first-child a {
        margin-left: 0; }
      #top-menu-ul .item_menu_Box li:last-child a {
        margin-right: 0; }
      #top-menu-ul .item_menu_Box li:last-child a:before {
        display: none; }
    #top-menu-ul .item_menu_Box li a {
      position: relative;
      display: block;
      line-height: 1;
      letter-spacing: 0.6px;
      border-bottom: 2px solid rgba(255, 255, 255, 0);
      font-size: 1.875rem;
      color: #000000;
      z-index: 5;
      font-weight: 700; }
      #top-menu-ul .item_menu_Box li a::before {
        position: absolute;
        content: '';
        right: -50px;
        width: 2px;
        height: 25px;
        top: 3px;
        background-color: #000; }
        @media (max-width: 767px) {
          #top-menu-ul .item_menu_Box li a::before {
            right: -55px;
            width: 1px;
            top: 0; } }
      @media (max-width: 767px) {
        #top-menu-ul .item_menu_Box li a {
          padding-bottom: 12px;
          margin: 0 56px 0 56px;
          font-size: 1.5rem; } }
      @media (min-width: 768px) {
        #top-menu-ul .item_menu_Box li a {
          margin: 0 50px;
          padding-bottom: 12px; }
          #top-menu-ul .item_menu_Box li a:hover {
            border-color: #BBC555;
            color: #BBC555; } }
    #top-menu-ul .item_menu_Box li.active a {
      border-color: #BBC555;
      color: #BBC555; }

.flex-direction-nav {
  display: none; }
  .flex-direction-nav a {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    transform: translateY(-50%);
    top: 28%;
    z-index: 10; }
    .flex-direction-nav a:after {
      position: absolute;
      top: 0; }
    @media (max-width: 767px) {
      .flex-direction-nav a {
        top: 21%;
        background-color: #fff; } }
  .flex-direction-nav a.flex-next {
    right: -60px; }
    @media (max-width: 767px) {
      .flex-direction-nav a.flex-next {
        right: -8px; } }
    .flex-direction-nav a.flex-next::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      border-left: 15px solid #000;
      border-top: 9px dashed transparent;
      border-bottom: 9px dashed transparent;
      width: 0;
      height: 0;
      transition: .4s ease; }
    @media (min-width: 768px) {
      .flex-direction-nav a.flex-next:hover::before {
        border-left: 15px solid #BBC555;
        border-top: 9px dashed transparent;
        border-bottom: 9px dashed transparent; } }
  .flex-direction-nav a.flex-prev {
    left: -60px; }
    @media (max-width: 767px) {
      .flex-direction-nav a.flex-prev {
        left: -8px; } }
    .flex-direction-nav a.flex-prev::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      border-right: 15px solid #000;
      border-top: 9px dashed transparent;
      border-bottom: 9px dashed transparent;
      width: 0;
      height: 0;
      transition: .4s ease; }
    @media (min-width: 768px) {
      .flex-direction-nav a.flex-prev:hover::before {
        border-right: 15px solid #BBC555;
        border-top: 9px dashed transparent;
        border-bottom: 9px dashed transparent; } }

.open_flexslider .flex-direction-nav {
  display: block; }

.sh-titlebx .t-bx {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 33px;
  position: relative; }
  @media (max-width: 767px) {
    .sh-titlebx .t-bx {
      margin-bottom: 12px; } }
  .sh-titlebx .t-bx::before {
    position: absolute;
    content: '';
    bottom: -20px;
    height: 12px;
    width: 100%;
    border-top: 2px solid #BBC555;
    border-bottom: 4px solid #BBC555; }
    @media (max-width: 767px) {
      .sh-titlebx .t-bx::before {
        bottom: -8px;
        height: 6px;
        border-top: 1px solid #bbc555;
        border-bottom: 2px solid #bbc555; } }
  .sh-titlebx .t-bx .ti {
    font-size: 2.5rem;
    line-height: 1.4;
    letter-spacing: 0.25px;
    font-weight: 700;
    color: #000000;
    font-family: "Noto Serif TC"; }
    @media (max-width: 767px) {
      .sh-titlebx .t-bx .ti {
        font-size: 1.875rem; } }
  .sh-titlebx .t-bx .enti {
    font-size: 2rem;
    line-height: 1.4;
    letter-spacing: 0.25px;
    font-weight: 400;
    color: #BBC555; }
    @media (max-width: 767px) {
      .sh-titlebx .t-bx .enti {
        font-size: 1.5rem;
        line-height: 1; } }
    .sh-titlebx .t-bx .enti br {
      display: none; }
      @media (max-width: 767px) {
        .sh-titlebx .t-bx .enti br {
          display: block; } }

.sh-titlebx .b-bx .des {
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: 1px;
  font-weight: 400;
  color: #BBC555; }
  @media (max-width: 767px) {
    .sh-titlebx .b-bx .des {
      font-size: 0.875rem; } }

.sh-conbx {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 767px) {
    .sh-conbx {
      display: block; } }
  .sh-conbx .l {
    font-size: 1.125rem;
    line-height: 2;
    letter-spacing: 1px;
    font-weight: 400;
    color: #6A6A6A; }
    @media (max-width: 767px) {
      .sh-conbx .l {
        font-size: 0.875rem;
        line-height: 1.6;
        letter-spacing: .5px; } }
  @media (max-width: 767px) {
    .sh-conbx .r {
      margin-top: 35px; } }
  .sh-conbx .r .con-btn {
    font-size: 1.125rem;
    line-height: 4;
    letter-spacing: 0.25px;
    font-weight: 400;
    color: #ffffff;
    background-color: #BBC555;
    display: block;
    width: 252px;
    height: 72px;
    border: 2px solid #BBC555;
    text-align: center;
    border-radius: 3px;
    font-family: "Noto Sans TC"; }
    @media (max-width: 767px) {
      .sh-conbx .r .con-btn {
        margin: 0 auto;
        width: 185px;
        height: 44px;
        font-size: 0.875rem;
        line-height: 3;
        letter-spacing: .5px;
        border-radius: 2.5px; } }
    .sh-conbx .r .con-btn span {
      padding-left: 45px;
      position: relative; }
      @media (max-width: 767px) {
        .sh-conbx .r .con-btn span {
          padding-left: 25px; } }
      .sh-conbx .r .con-btn span img {
        position: absolute;
        left: 0;
        top: 0;
        transition: .4s ease; }
        @media (max-width: 767px) {
          .sh-conbx .r .con-btn span img {
            width: 19px;
            top: 3px; } }
      .sh-conbx .r .con-btn span .unhove {
        opacity: 1; }
      .sh-conbx .r .con-btn span .hove {
        opacity: 0; }
    @media (min-width: 768px) {
      .sh-conbx .r .con-btn:hover {
        background-color: #FFF;
        color: #BBC555; }
        .sh-conbx .r .con-btn:hover span .unhove {
          opacity: 0; }
        .sh-conbx .r .con-btn:hover span .hove {
          opacity: 1; } }

.sh-inbanner {
  width: 100%;
  position: relative;
  margin-bottom: 280px; }
  @media (max-width: 767px) {
    .sh-inbanner {
      margin-bottom: 100px; } }
  .sh-inbanner .bg1 {
    position: absolute;
    bottom: -175px;
    right: 60px; }
    @media (max-width: 767px) {
      .sh-inbanner .bg1 {
        bottom: -35%;
        right: 5px;
        width: 65%; } }
  .sh-inbanner .bgcover {
    background-size: cover !important;
    height: 380px; }
    @media (max-width: 767px) {
      .sh-inbanner .bgcover {
        padding-bottom: 49.4%;
        height: 0; } }
  .sh-inbanner h1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: left;
    padding-left: 17.7vw; }
    @media (max-width: 1199px) {
      .sh-inbanner h1 {
        padding-left: 12vw; } }
    @media (max-width: 767px) {
      .sh-inbanner h1 {
        padding-left: 6vw; } }
    .sh-inbanner h1 span {
      position: relative;
      display: block; }
      .sh-inbanner h1 span .en {
        font-size: 4.625rem;
        line-height: 1.2;
        letter-spacing: 0.25px;
        font-weight: 500;
        color: #ffffff;
        text-shadow: #aa9800 0px 0px 19px; }
        @media (max-width: 767px) {
          .sh-inbanner h1 span .en {
            font-size: 2.8125rem;
            line-height: 1; } }
      .sh-inbanner h1 span .tw {
        font-size: 3.1875rem;
        line-height: 1.2;
        letter-spacing: 0.25px;
        font-weight: 500;
        color: #ffffff;
        text-shadow: #aa9800 0px 0px 19px;
        font-family: "Noto Serif TC"; }
        @media (max-width: 767px) {
          .sh-inbanner h1 span .tw {
            font-size: 2.0625rem; } }

.in_fade {
  opacity: 0;
  transition: 1s ease-in-out;
  transform: translateY(25px); }

.in_fade.active {
  opacity: 1;
  transform: translateY(0px); }

.hiddendiv {
  display: none; }
