/// 需要推的padding-bottom以及預設圖用當前頁指定到此calss
.bgcover{
    width: 100%;
    height: 0;
    background: 50%/cover;
}
.mo{
    display:none;
    @media (max-width:767px) {
        display: block;
    }
}
.pc{
    display: none;
    @media (min-width:768px) {
        display: block;
    }
}
.container{
    padding: 0 16px;
    margin: 0 auto;
    width: 100%;
    @media (max-width:767px) {
        padding: 0 25px;
    }
    @media (min-width:576px) {
        max-width: 540px;
    }
    @media (min-width:768px) {
        max-width: 720px;
    }
    @media (min-width:992px) {
        max-width: 960px;
    }
    @media (min-width:1200px) {
        max-width: 1056px;
    }
}

// top-menu
#top-menu-ul{
   
    @media (max-width:767px) {
        margin:0 auto 35px;
    }
    @media (min-width:768px) {
        margin:0 auto 50px;
    }
    
    position: relative;
    z-index: 1;
    .item_Menu{
        margin: 0 auto;   
        overflow: hidden;    
        height: 56px;
    }
    .item_menu_Box{    
        width:100%;
        overflow:hidden;
        overflow-x: scroll;
        position: relative;
        text-align:center;
        .item_menu_list{
            white-space: nowrap !important;
            padding-bottom: 0px;
            text-align: center;
            font-size:0px;
            display:inline-flex; 
            position: relative;
            @media (min-width:768px) {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
            &::before{
                position: absolute;
                content: '';
                bottom: 12px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #C8C8C8;
                z-index: 3;
                @media (max-width:767px) {
                    bottom: 19px;
                }
            }
        }

        li{
            height: 56px;
            &:first-child a{
                margin-left: 0;
            }
            &:last-child a{
                margin-right: 0;
            }
            &:last-child a:before{
                display: none;
            }
            
        }
        li a{
            position: relative;
            display: block;
            line-height: 1;
            letter-spacing: 0.6px;
            border-bottom: 2px solid rgba(255,255,255,0);
            font-size: pxToEm(30);
            color: #000000;
            z-index: 5;
            font-weight: 700;
           
            &::before{
                position: absolute;
                content: '';
                right: -50px;
                width: 2px;
                height: 25px;
                top: 3px;
                background-color: #000;
                @media (max-width:767px) {
                    right: -55px;
                    width: 1px;
                    top: 0;
                }
            }

            @media (max-width:767px) {
                padding-bottom:12px;
                margin:0 56px 0 56px; 
                font-size: pxToEm(24);
            }
            @media (min-width:768px) {
                margin:0 50px; 
                padding-bottom:12px;
                &:hover{
                    border-color:$hover;
                    color:$hover;
                }
            }
            
          
        }
        li.active a{
            border-color:$hover;
            color:$hover;
        }
    }
}
.flex-direction-nav{
    display:none;
    a{
        display:block;
        width:40px;   
        height: 40px;
        position: absolute;        
        transform: translateY(-50%);
        top:28%;
        z-index: 10;
        // background-color: #000000;
        &:after{
            position: absolute;
            top: 0;
        }
        @media (max-width:767px) {
            top: 21%;
            background-color: #fff;
        }
    }
    a.flex-next{
        // background: url(../svg/slick-next.svg) no-repeat center / 15px;
        right: -60px;
        @media (max-width:767px) {
            right: -8px;
        }
        &::before{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            content: '';
            border-left: 15px solid #000;
            border-top: 9px dashed transparent;
            border-bottom: 9px dashed transparent;
            width: 0;
            height: 0;
            transition: .4s ease;
        }
        @media (min-width:768px) {
            &:hover{
                &::before{
                    border-left: 15px solid $hover;
                    border-top: 9px dashed transparent;
                    border-bottom: 9px dashed transparent;
                }
            }
        }
    }

    a.flex-prev{
        // background: url(../svg/slick-prev.svg) no-repeat center / 15px;
        left:  -60px;
        @media (max-width:767px) {
            left: -8px;
        }
        &::before{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            content: '';
            border-right: 15px solid #000;
            border-top: 9px dashed transparent;
            border-bottom: 9px dashed transparent;
            width: 0;
            height: 0;
            transition: .4s ease;
        }
        @media (min-width:768px) {
            &:hover{
                &::before{
                    border-right: 15px solid $hover;
                    border-top: 9px dashed transparent;
                    border-bottom: 9px dashed transparent;
                }
            }
        }
    }
}
.open_flexslider{
    .flex-direction-nav{
        display: block;
    }
}

 ////   popup

//  .pop-act{
//     background: rgba(0, 0, 0, 0.8);
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     z-index: 999;
//     display: none;
//     .vo_signbg{
//         background: url(../images/qr.png) no-repeat;
//         position: relative;
//         width: 300px;
//         height: 300px;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//         @media (max-width:992px) {
//             background-size:450px;
//             background-position: center center;
//             width: 450px; 
//         }
//         @media (max-width:576px) {
//             width: 320px;
//             background-size:320px;
//         }
//     }
//     .clos-btn{
//         width: 60px;
//         position: absolute;
//         top: -30px;
//         right: -90px;
//         border-radius: 99px;
//         display: block;
//         height: 20px;
//         cursor: pointer;
//         @media (max-width:576px) {
//             // top: 0px;
//             right: -20px;
//             width: 40px;
//             padding-bottom: 40px;
//         }
//         &:before{
//             content: '';
//             width: 30px;
//             height: 2px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             // transform: rotate(45deg);
//             transform: translate(-50%,-50%) rotate(45deg);
//             background: #ffffff;
//             @media (max-width:576px) {
//                 width: 15px;
//             }
//         }
//         &:after{
//             content: '';
//             width: 30px;
//             height: 2px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             // transform: rotate(45deg);
//             transform: translate(-50%,-50%) rotate(-45deg);
//             background: #ffffff;
//             @media (max-width:576px) {
//                 width: 15px;
//             }
//         }
//     }
// }
// .show-pop{
//     display: block !important;
//     }
.sh-titlebx{
    .t-bx{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 33px;
        position: relative;
        @media (max-width:767px) {
            margin-bottom: 12px;
        }
        &::before{
            position: absolute;
            content: '';
            bottom: -20px;
            height: 12px;
            width: 100%;
            border-top: 2px solid $hover;
            border-bottom: 4px solid $hover;
            @media (max-width:767px) {
                bottom: -8px;
                height: 6px;
                border-top: 1px solid #bbc555;
                border-bottom: 2px solid #bbc555;
            }
        }
        .ti{
            @include fontset(40,1.4,.25,700);
            font-family: $ser;
            @media (max-width:767px) {
                font-size: pxToEm(30);
            }
        }
        .enti{
            @include fontset(32,1.4,.25,400,$hover);
            @media (max-width:767px) {
                font-size: pxToEm(24);
                line-height: 1;
            }
            br{
                display: none;
                @media (max-width:767px) {
                    display: block;
                }
            }
        }
    }
    .b-bx{
        .des{
            @include fontset(18,1.5,1,400,$hover);
            @media (max-width:767px) {
                font-size: pxToEm(14);
            }
        }
    }
}

.sh-conbx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:767px) {
        display: block;
    }
    .l{
        @include fontset(18,2,1,400,#6A6A6A);
        @media (max-width:767px) {
            font-size: pxToEm(14);
            line-height: 1.6;
            letter-spacing: .5px;
        }
    }
    .r{
        @media (max-width:767px) {
            margin-top: 35px;
        }
        .con-btn{
            @include fontset(18,4,.25,400,#ffffff);
            background-color: $hover;
            display: block;
            width: 252px;
            height: 72px;
            border: 2px solid $hover;
            text-align: center;
            border-radius: 3px;
            font-family: $font_content;
            @media (max-width:767px) {
                margin: 0 auto;
                width: 185px;
                height: 44px;
                font-size: pxToEm(14);
                line-height: 3;
                letter-spacing: .5px;
                border-radius: 2.5px;
            }
            span{
                padding-left: 45px;
                position: relative;
                @media (max-width:767px) {
                    padding-left: 25px;
                }
                img{
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: .4s ease;
                    @media (max-width:767px) {
                        width: 19px;
                        top: 3px;
                    }
                }
                .unhove{
                    opacity: 1;
                }
                .hove{
                    opacity: 0;
                }
            }
            @media (min-width:768px) {
                &:hover{
                    background-color: #FFF;
                    color: $hover;
                    span .unhove{
                        opacity: 0;
                    }
                    span .hove{
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.sh-inbanner{
    width: 100%;
    position: relative;
    margin-bottom: 280px;
    @media (max-width:767px) {
        margin-bottom: 100px;
    }
    .bg1{
        position: absolute;
        bottom: -175px;
        right: 60px;
        @media (max-width:767px) {
            bottom: -35%;
            right: 5px;
            width: 65%;
        }
    }
    .bgcover{
        // padding-bottom: 19.7%;
        background-size: cover !important;
        height: 380px;
        @media (max-width:1400px) {
            
        }
        @media (max-width:767px) {
            padding-bottom: 49.4%;
            height: 0;
        }
    }
    h1{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: left;
        padding-left: 17.7vw;
        @media (max-width:1199px) {
            padding-left: 12vw;
        }
        @media (max-width:767px) {
            padding-left: 6vw;
        }
        span{
            position: relative;
            display: block;
            .en{
                @include fontset(74,1.2,.25,500,#ffffff);
                text-shadow: rgb(170, 152, 0) 0px 0px 19px;
                @media (max-width:767px) {
                    font-size: pxToEm(45);
                    line-height: 1;
                }
            }
            .tw{
                @include fontset(51,1.2,.25,500,#ffffff);
                text-shadow: rgb(170, 152, 0) 0px 0px 19px;
                font-family: $ser;
                @media (max-width:767px) {
                    font-size: pxToEm(33);
                }
            }
        }
    }
}

.in_fade{
    opacity: 0;
    transition: 1s ease-in-out;
    transform: translateY(25px);
}

.in_fade.active{
    opacity: 1;
    transform: translateY(0px);
}

.hiddendiv{
    display: none;
}